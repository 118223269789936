import React from "react";
import { WrapWordP, WordP, SelectWordP, PointTime, WrapParagraph, SelectTimePoint } from './Style'
import { incorrect } from "keys/types";
import { useSelector } from "react-redux";

const Paragraph = (props) => {
    const { words, title, settings, startIndex } = props
    const { markOption, displayResults } = settings || {}
    const { assessment } = useSelector(state => state.assessment)
    const { end = words?.length + startIndex } = assessment || {}

    return (
        <WrapParagraph $title={title} $markOption={markOption}>
            {words?.map((word, index) => {
                const { id, word_def_text, transcription, customStatus, minorError, removed, time_point, is_time_point_word, status, word_def_fe_details = {}, pointTime } = word
                const { break_line } = word_def_fe_details || {}
                const error = status === incorrect
                let renderData = true
                if (end)
                    renderData = index + startIndex <= end - 1
                if (!displayResults) renderData = true

                if (renderData)
                    return (
                        <>
                            <WrapWordP $removed={removed} $minorError={minorError} key={id} $error={error} $customStatus={customStatus}>
                                {displayResults && is_time_point_word &&
                                    <PointTime>
                                        {time_point}
                                    </PointTime>}
                                <WordP $error={error} >
                                    {transcription || word_def_text}
                                </WordP>
                            </WrapWordP>
                            {break_line && <span style={{ width: '100%' }}></span>}
                        </>
                    )
            })}
        </WrapParagraph>
    )
}

export default Paragraph