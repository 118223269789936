import { BorderBottom, DisabledByDefault } from '@mui/icons-material'
import { createTheme, alpha } from '@mui/material/styles'
import ratingList from 'keys/rating'
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';

const secondary = '#FB7401'
const primary = '#8BBC01'
const primary2 = "#05BE24"
const thirdly = "#91BDFA"
const thirdly2 = "#CCE1FF"
const thirdly3 = "#CADDEA"
const lightPrimary = "#f6fbdb"
const lightBlue2 = "#e3f2f9"
const lightBlue3 = "#00b0f0"
const dark = '#26445A'
const done = "#3da288"
const black = "#000"
const light = "#F3F6F9"
const lightSecondary = "#ff9453"
const lightBlue = '#3EBCE226'
const lightOrange = '#FB5B0126'
const lightPurple = '#863EE226'
const error = '#FF0000'
const mandatory = "#E34141"
const text = "rgba(0, 0, 0, 0.50)"
const white = "#FFF"
const gray = "#B5B5B5"
const lightDark = "#416783"
const ligthGray = "rgb(221, 221, 221)"
const lightYellow = "#ffe9a5"
const yellow = "#eec808"
const success = "#188038"
const bg = "#E6F0FE"
const rating1 = ratingList[0]
const rating2 = ratingList[1]
const rating3 = ratingList[2]
const rating4 = ratingList[3]
const rating5 = ratingList[4]

const sidebar = 170
const header = 65

const gTheme = {
    thirdly: {
        main: thirdly,
        contrastText: white
    },
    done: {
        main: done,
        contrastText: white
    },
    bg: {
        main: bg,
        contrastText: black
    },
    text: {
        main: text,
        contrastText: text
    },
    thirdly3: {
        main: thirdly3,
        contrastText: dark
    },
    thirdly2: {
        main: thirdly2,
        contrastText: dark
    },
    gray: {
        main: gray,
        contrastText: white
    },
    primary2: {
        main: primary2,
        contrastText: white
    },
    rating1: {
        main: rating1,
        contrastText: white
    },
    lightBlue3: {
        main: lightBlue3,
        contrastText: white
    },
    lightBlue2: {
        main: lightBlue2,
        contrastText: dark
    },
    rating2: {
        main: rating2,
        contrastText: white
    },
    rating3: {
        main: rating3,
        contrastText: white
    },
    rating4: {
        main: rating4,
        contrastText: white
    },
    rating5: {
        main: rating5,
        contrastText: white
    },
    lightBlue: {
        main: lightBlue,
        contrastText: "#3EBCE2",
    },
    lightDark: {
        main: "#416783",
        contrastText: "#B6B9C7",
    },
    yellow: {
        main: yellow,
        contrastText: white
    },
    success: {
        main: success,
        contrastText: success,
    },
    lightYellow: {
        main: lightYellow,
        contrastText: dark
    },
    gray2: {
        main: ligthGray,
        contrastText: 'black'
    },
    dark: {
        main: '#26445A',
        contrastText: "white",
    },
    lightOrange: {
        main: lightOrange,
        contrastText: "#FB5B01",
    },
    ligthGray: {
        main: gray,
        contrastText: ligthGray
    },
    disabled: {
        main: "",
        contrastText: '#a0a2a3'
    },
    primary: {
        main: '#8BBC01',
        contrastText: "white"
    },
    lightPrimary: {
        main: lightPrimary,
        contrastText: dark
    },
    lightSecondary: {
        main: "#ff9453",
        contrastText: "white"
    },
    secondary: {
        main: '#FB7401',
        contrastText: "white"
    },
    lightPurple: {
        main: lightPurple,
        contrastText: "#863EE2",
    },
    lightGreen: {
        main: '#C1E23E59',
        contrastText: "#8BBC01",
    },
    red: {
        main: error,
        contrastText: '#FB010126',
    },
    lightRed: {
        main: '#FB010126',
        contrastText: "#FB0101",
    },
    light: {
        main: '#F3F6F9',
        contrastText: dark,
    },
    white: {
        main: white,
        contrastText: dark,
    },
}

const theme = createTheme({
    typography: {
        fontFamily: [
            // '-apple-system',
            // 'BlinkMacSystemFont',
            // '"Segoe UI"',
            // 'Roboto',
            // '"Helvetica Neue"',
            // 'Arial',
            // 'sans-serif',
            // '"Apple Color Emoji"',
            // '"Segoe UI Emoji"',
            // '"Segoe UI Symbol"',

            "Calibri", "sans-serif"

        ].join(',')
    },

    // direction: 'rtl',
    palette: gTheme,
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.variant === 'outlined' &&
                    // ownerState.color === 'primary' && 
                    {
                        color: dark,
                    }),
                    fontWeight: "400",
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                }),
            },
        },
        MuiLoadingButton: {
            styleOverrides: {
                root: () => ({
                    boxShadow: 'none'
                }),
            },
        },
        MuiButtonGroup: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    direction: "ltr"
                }),
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: () => ({
                    margin: "5px 5px 5px 5px",
                    width: "50px",
                    display: "flex",
                    alignItems: "center",
                }),
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: () => ({
                }),
            }
        },
        MuiFab: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    "&:hover": {
                        backgroundColor: 'primary.main',
                        // opacity: 0.9
                    }
                }),
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        cursor: "pointer",
                        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                        borderColor: theme.palette.primary.main,
                    }
                })
            }
        },
        MuiInput: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        cursor: "pointer",
                        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                        borderColor: theme.palette.primary.main,
                    }
                })
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    transition: theme.transitions.create([
                        'border-color',
                        'background-color',
                        'box-shadow',
                    ]),
                    '&:focus': {
                        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                        borderColor: theme.palette.primary.main,
                    },
                }),
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    borderBottom: '1px solid #EFF2F5',
                    marginBottom: '10px',
                    fontSize: '17px'
                }),
            },
        },
        MuiLinearProgress: {
            styleOverrides: {

            }
        }
    }
},

)

const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

export {
    cacheRtl, thirdly, bg, lightDark, done, yellow, rating1, lightSecondary, rating2, rating3, rating4, rating5,
    lightBlue2, lightPrimary, text, thirdly3, ligthGray, gTheme, gray, header, sidebar, white, mandatory, error, primary, secondary, dark, black, light, lightBlue, lightPurple, lightOrange
}
export default theme