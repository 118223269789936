import styled from "styled-components";

const WrapWarningCell = styled.div`

`

const TestTakerName = styled.div`
font-size:20px;
font-weight: bold;
`

const TestTakerDetails = styled.div`
font-size:16px;
`

export { WrapWarningCell, TestTakerName, TestTakerDetails }