import { currentDateTime, decimalToTime } from "utils/formatter"
import { GridActionsCellItem } from "@mui/x-data-grid"
import { CallMissedOutgoing, RemoveRedEyeOutlined, Replay } from "@mui/icons-material"
import { CircularProgress } from "@mui/material"
import { AdminAssessmentsCrud } from "components/Admin/AdminAssessments/hooks"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "store"
import { useNavigate } from "react-router-dom"
import { app, transcription } from "components/Router/routes"

const useFields = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { statusFilters } = useSelector(state => state.assessment)
    const { backToMarkingWordsLoading, openBackToTranscriptionConfirmation } = AdminAssessmentsCrud()

    const selectAssessment = async (assessment) => {
        dispatch(actions.setAssessment(assessment))
        dispatch(actions.setTestTaker({ test_taker_id: assessment.test_taker_id }))
    }

    const goWords = (assessment) => {
        selectAssessment(assessment)
        navigate(`${app.transcriptionAssessments}/${assessment.id}`, {
            state: {
                statusFilters: statusFilters
            }
        })
    }

    return [
        {
            field: 'update_date',
            headerName: "עדכון אחרון",
            width: 150,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (value) => `${currentDateTime(value.row?.update_date) || ""}`,
        },
        {
            field: 'marking_words_update_by_username',
            headerName: 'סומן ע"י',
            type: 'number',
            width: 150,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'transcription_duration',
            headerName: "משך תעתוק",
            type: 'string',
            width: 150,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ row }) => decimalToTime(row.transcription_duration),
        },
        {
            field: 'transcription_start_date',
            headerName: " התחלת תעתוק",
            width: 150,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ row }) => `${currentDateTime(row?.transcription_start_date) || ""}`,
        },
        {
            field: 'transcription_end_date',
            headerName: " סיום תעתוק",
            width: 150,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ row }) => `${currentDateTime(row?.transcription_end_date) || ""}`,
        },
        {
            field: 'enter_transcription',
            type: 'actions',
            headerName: "כניסה",
            width: 110,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            cellClassName: 'actions',
            getActions: ({ row }) => {

                return [
                    <GridActionsCellItem
                        icon={<CallMissedOutgoing color='rating1' />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => {
                            goWords(row, null)
                        }}
                        color="secondary"
                    />
                ];
            }
        },
        {
            field: 'view_marking_words',
            type: 'actions',
            headerName: "צפייה",
            width: 110,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            cellClassName: 'actions',
            getActions: ({ row }) => {

                return [
                    <GridActionsCellItem
                        icon={<RemoveRedEyeOutlined color='rating1' />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => {
                            goWords(row, null)
                        }}
                        color="secondary"
                    />
                ];
            }
        },
        {
            field: 'back_to_transcription',
            type: 'actions',
            headerName: "החזר לתעתוק",
            width: 110,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            cellClassName: 'actions',
            getActions: ({ row }) => {

                const loading = backToMarkingWordsLoading
                return [
                    <GridActionsCellItem
                        icon={loading ? <CircularProgress color='yellow' size={20} /> : <Replay color='yellow' />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => openBackToTranscriptionConfirmation(row, true)}
                        color="secondary"
                        disabled={loading}
                    />
                ];
            }
        },
    ]
}

export default useFields 
