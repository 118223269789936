import { produce } from 'immer'
import createReducer from "./reducerUtils"
import { cloneDeep } from 'lodash'
import alerts from 'keys/alerts'
import toast from 'keys/toast'
import loading from 'keys/loading'
import dataGridModel from 'keys/dataGridModel';
import api from 'keys/apis'


const initialState = {
    menuIndex: 0,

    snackbars: {
        auth: {
            open: false,
            text: null,
            type: 'auth'
        },
        [toast.createTestTaker]: {
            open: false,
            text: "התלמיד נוצר בהצלחה",
            type: toast.createTestTaker
        },
        [toast.createAffiliation]: {
            open: false,
            text: "היחידה נוצר בהצלחה",
            type: toast.createAffiliation
        },
        [toast.createAssessment]: {
            open: false,
            text: "נשמר בהצלחה",
            type: toast.createAssessment
        }
    },
    hideRecord: false,
    alerts: {
        [alerts.createAffliation]: {
            open: false,
            close: { key: alerts.createAffliation, value: { open: false } }
        },
        [alerts.createTestTaker]: {
            open: false,
            close: { key: alerts.createTestTaker, value: { open: false } }
        },
        [alerts.backTo]: {
            open: false,
            props: null,
            close: { key: alerts.backTo, value: { open: false } }
        },
        [alerts.createGrade]: {
            open: false,
            props: null,
            close: { key: alerts.createGrade, value: { open: false } }
        },
        [alerts.createClass]: {
            open: false,
            props: null,
            close: { key: alerts.createClass, value: { open: false } }
        },
        [alerts.newChild]: {
            open: false,
            props: null,
            close: { key: alerts.newChild, value: { open: false } }
        },
        [alerts.recordInstructions]: {
            open: false
        },
        [alerts.noBalance]: {
            open: false
        }
    },
    loading: {
        [loading.markingWords]: false,
        [loading.updateAsssessmentStatus]: false,
        [loading.createAssessmentWithFile]: false,
        [loading.printAssessment]: false
    },
    selectedSidebarIndex: 0,

    columnItems: {},
    controllers: {
        [api.report]: () => { },
        [api.get_with_last_assessment]: () => { },
        [api.test_taker]: () => { },
        [api.assessments]: () => { },
        [api.get_audio_file]: () => { },
    }
}

const config = {
    updateController(state, action) {
        state.controllers = { ...state.controllers, ...action.payload }
    },
    cleanController(state, action) {
        const controllers = action.payload
        let nControllers = {}
        controllers.map((controler) =>
            nControllers[controler] = new AbortController()
        )
        state.controllers = { ...state.controllers, ...nControllers }
    },
    setSelectedSidebarIndex(state, action) {
        state.selectedSidebarIndex = action.payload
    },
    setMenuIndex(state, action) {
        state.menuIndex = action.payload
    },
    setSnackbars(state, action) {
        const { type = "auth", open } = action.payload
        let nSnackbars = cloneDeep(state.snackbars)
        nSnackbars[type] = { ...action.payload }
        state.snackbars = nSnackbars
    },
    setColumnItems(state, action) {
        const { key, value } = action.payload
        let nColumnItems = cloneDeep(state.columnItems)
        nColumnItems[key] = value
        state.columnItems = nColumnItems
    },
    updateColumnItems(state, action) {
        state.columnItems = { ...state.columnItems, ...action.payload }
    },
    setLoading(state, action) {
        const nLoading = {}
        Object.keys(action.payload).map((key) => {
            nLoading[key] = action.payload[key]
        })
        state.loading = { ...state.loading, ...nLoading }
    },
    setHideRecord(state, action) {
        state.hideRecord = action.payload
    },
    setAlerts(state, action) {
        const { key, value } = action.payload
        state.alerts[key] = { ...state.alerts[key], ...value }
    }
}

export default produce(
    (state, action) => createReducer(state, action, config),
    initialState
)

