import React, { useEffect } from 'react'
import { MarkingWordsCrud, MarkingWordsData } from './hooks'
import { useSelector } from 'react-redux'
import { Page, Record, Words } from 'components'
import { CustomSection } from 'styles/GlobalStyle'
import { Button } from 'components/Form'
import { WrapFooter, WrapActionBtn } from './Style'
import { AdminAssessmentsCrud, AdminAssessmentsData } from 'components/Admin/AdminAssessments/hooks'
import markingWordsHooks from './hooks'

const MarkingWords = () => {
    const { actionBtns, headerActions } = MarkingWordsData()
    const { startMarkingWordsData } = AdminAssessmentsCrud()
    const { init, cleanData, goAdminAssessments } = MarkingWordsCrud()
    const { assessment } = useSelector(state => state.assessment)
    const { words } = useSelector(state => state.words)
    const { isCloseSttoper } = useSelector(state => state.markingWords)

    const startMark = markingWordsHooks.useStartMarkingWords()

    const { assessment_def_name } = assessment || {}

    useEffect(() => {
        init()
    }, [words])

    useEffect(() => {
        return (() => {
            cleanData()
        })
    }, [])

    const disabledContent = isCloseSttoper

    return (
        <Page>
            <CustomSection height={160} $bg align="center">
                <Record autoplay={startMarkingWordsData} changeSetting stopAudioAfterSeconds={60} showTimeline />
            </CustomSection>
            <Words
                disabled={disabledContent}
                customHeader={{
                    left: null,
                    center: startMark,
                    right: headerActions
                }}
                type={'WORD_GROUP'}
                settings={{
                    markOption: true
                }}
                name={assessment_def_name}
                words={words}
                hidePoints={true}
                customGoBack={goAdminAssessments}
            />
            {words &&
                <WrapFooter $disabled={disabledContent}>
                    {actionBtns.map((actionBtn, index) =>
                        <WrapActionBtn key={index} width={actionBtn.width}>
                            <Button {...actionBtn} fullWidth />
                        </WrapActionBtn>
                    )}
                </WrapFooter>
            }
        </Page>
    )
}

export default MarkingWords