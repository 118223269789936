import React, { useEffect } from 'react'
import { WrapReports, WrapDetailesTestTaker, WrapTitle, WrapGradeGraph, WrapRatingSections } from './Style'
import { CustomSection } from 'styles/GlobalStyle'
import { RatingCrud, RatingData } from './hooks'
import { GradeGraph } from 'components/School/Rating'
import { Page, Record } from 'components'
import { Divider } from '@mui/material'

const Rating = () => {
    const { ratingSections, detailsTestTakerSections } = RatingData()
    const { init, cleanData } = RatingCrud()

    useEffect(() => {
        init()

        return (() => {
            cleanData()
        })
    }, [])

    return (
        <Page>
            <WrapReports>
                <WrapDetailesTestTaker>
                    {detailsTestTakerSections.map((section, index) => {
                        const { bg, shrink, width, grow, id, content } = section

                        return (
                            <CustomSection
                                key={index}
                                width={width}
                                $bg={bg}
                                $shrink={shrink}
                                $grow={grow}
                            >
                                {content}
                            </CustomSection>
                        )
                    })}
                </WrapDetailesTestTaker>

                <CustomSection $flex={false} $bg $shrink height={100}>
                    <Record />
                </CustomSection>
                <WrapRatingSections>
                    {ratingSections.map((section, index) => {
                        const { grow, center, scroll, flex, bg, width, title, flexDirection } = section

                        return (
                            <CustomSection
                                key={index}
                                $grow={grow}
                                $center={center}
                                $flex={flex}
                                $column
                                $bg={bg}
                                width={width}
                                $title={title}
                                $scroll={scroll}
                            >
                                {title &&
                                    <>
                                        <WrapTitle>
                                            {title}
                                        </WrapTitle>
                                        <Divider />
                                    </>
                                }
                                <section.component />
                            </CustomSection>
                        )
                    })}
                </WrapRatingSections>
                <CustomSection $bg $shrink height={100} $flex $center>
                    <GradeGraph />
                </CustomSection>
            </WrapReports>
        </Page>
    )
}

export default Rating