import styled, { css } from "styled-components"
import { dark } from "styles/theme"
import { Link } from "styles/GlobalStyle"
import { Center } from "styles/GlobalCss"

const WrapAuth = styled.div`
display: flex;
height: 100%;
`

const InstructionItem = styled.li`
color: rgba(0, 0, 0, 0.50);
font-size: 17px;
`

const Instructions = styled.ul`
margin-block-start: inherit;
display: flex;
flex-direction: column;
row-gap: 6px;
`

const FormContent = styled.div`
width:400px;

`

const ForgotUserNameOrPassword = styled.div`
height:50px;
${Center};
column-gap: 20px;
`

const RegisterLink = styled(Link)`
    position: relative;
    top: 100px;
`

const WrapLogo = styled.div`
width: 208.77px;
height: 156.28px;
`

const Left = styled.div`
background:${dark};
width:40%;
display: flex;
align-items: center;
justify-content: center;
`

const Right = styled.div`
width:60%;
display: flex;
align-items: center;
justify-content: center;
`



export { FormContent, WrapLogo, ForgotUserNameOrPassword, WrapAuth, Instructions, InstructionItem, RegisterLink, Left, Right }