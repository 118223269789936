import React from 'react'
import { WrapButton, WrapBlinkButton } from './Styled'
import { gTheme } from 'styles/theme';
import { Permissions } from 'components'

import PermissionHOC from 'components/Permissions/PermissionHOC'

const Submit = (props) => {
    const { blink, arrayIndex, gridArea, children, fontsize, className, systemUI = true, variant, hide, width, autoFocus, loading, rightIcon, align, $height, direction, disabledColor, icon, disabled, label, color, action, fullWidth, feature } = props;

    const Button = blink ? WrapBlinkButton : WrapButton

    return (
        hide ||
        <Button
            fontsize={fontsize}
            $systemUI={systemUI}
            $fullWidth={fullWidth}
            className={className}
            gridArea={gridArea ? `${gridArea}_${arrayIndex}` : null}
            onClick={() => action(props)}
            autoFocus={autoFocus}
            color={color}
            direction={direction}
            disabledcolor={gTheme[disabledColor]}
            disabled={feature?.disabled || disabled}
            align={align}
            $height={$height}
            width={width}
            loading={loading}
            variant={variant || 'contained'}>
            {children ||
                <>
                    {icon}
                    {label}
                </>
            }
        </Button>
    )
}

export default PermissionHOC(Submit)