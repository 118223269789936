import React, { useRef } from 'react'
import { WrapMarkWordsContent, WrapMarkWords, WrapScreenMarkWords, WrapCustomSection } from './Style'
import { WordsData } from 'components/Words/hooks'
import { useLocation } from 'react-router-dom'
import { components } from 'keys'
import { Header } from 'components/Words'
import { Stepper } from 'components/School/MakingRecordAssessments'

const Words = (props) => {
    const { words, type, settings, disabled, student } = props
    const { mappingMarkByType, actionBtns } = WordsData()
    const { state = {} } = useLocation();
    const contentToPrint = useRef(null);

    const { header = props.header, footer = props.footer } = state || {}
    const studentView = student || state?.student

    let renderType = mappingMarkByType[type || "DEFAULT"]
    const Component = studentView ? renderType.studentC : renderType.component


    return (
        <WrapScreenMarkWords>
            <Header renderType={renderType}{...props} contentToPrint={contentToPrint} />
            {components?.[header]}
            <WrapMarkWords ref={contentToPrint} >
                <WrapMarkWordsContent $disabled={disabled}>
                    <WrapCustomSection $bg shadow={3} align="center" $flex $column>
                        <Stepper />
                        <Component words={words} settings={settings} />
                    </WrapCustomSection>
                </WrapMarkWordsContent>
            </WrapMarkWords>
            {components?.[footer]}
        </WrapScreenMarkWords>
    )
}

export default Words