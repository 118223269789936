const alerts = {
    createAffliation: "createAffliation",
    createTestTaker: "createTestTaker",
    backTo: "backTo",
    createGrade: "createGrade",
    createClass: "createClass",
    newChild: "newChild",
    recordInstructions: "recordInstructions",
    noBalance:"noBalance"
}

export default alerts