import React, { useEffect } from 'react'
import { validate } from '.'
import { BasicAlert } from 'components'
import { WrapCreateAffiliation } from './Style'
import { Form } from 'components/Form'
import { CreateClassCrud, CreateClassData } from './hooks'
import { useSelector } from 'react-redux'
import tClass from 'components/School/CreateClass/hooks'
import { pulldowns } from 'store/hooks'

const CreateClass = () => {
    const { testTaker } = useSelector(state => state.testTaker)
    const { formName, fields, title, submitBtns } = CreateClassData()
    const { handleCreateClassAlert } = CreateClassCrud()
    const { headerFilters } = useSelector(state => state.header)
    const formValues = useSelector(state => state.form[formName]?.values)

    const createClass = tClass.useCreateClass()

    const handleGroup = pulldowns.useHandleGroup()
    const handleGrade = pulldowns.useHandleGrade()

    useEffect(() => { handleGroup(formValues) }, [formValues?.group])
    useEffect(() => { handleGrade(formValues) }, [formValues?.grade])

    return (
        <BasicAlert title={title} handleAlert={handleCreateClassAlert}>
            <Form
                form={formName}
                title={null}
                validations={(values) => validate(values, testTaker)}
                fields={fields}
                csswrap={WrapCreateAffiliation}
                submitBtns={submitBtns}
                submit={createClass}
                initialValues={headerFilters}
            />
        </BasicAlert>
    )
}

export default CreateClass