import React from "react";
import { WrapWordList, WrapCustomSection, WrapAddWord, WrapWord, Time, WrapResult, FabDetail, WrapWordContent, WrapWordText, WordItem, WrapSettings, Word, SelectWord, SelectTimePoint, PointTime } from './Style'
import { useSelector } from "react-redux";
import { incorrect, na } from "keys/types";
import { WordSettings } from ".";
import { gTheme, secondary } from "styles/theme";
import { WordsData } from "./hooks";
import { Add } from "@mui/icons-material";
import { IconButton } from "components/Form";
import { CustomSection } from "styles/GlobalStyle";
import { Stepper } from "components/School/MakingRecordAssessments";

const WordList = (props) => {
    const { words, settings } = props
    const { markOption, displayResults } = settings || {}
    const { displayResultsSettings } = WordsData()
    const { assessment } = useSelector(state => state.assessment)
    const { end = words?.length, timePoint = {} } = assessment || {}

    return (
        <WrapWordList $markOption={markOption}>
            {words?.map((word, index) => {
                const { removed, minorError, word_def_text, customStatus, transcription, status, word_def_fe_details } = word
                const { break_line } = word_def_fe_details || {}

                let renderData = index <= end
                if (!displayResults) renderData = true

                const settingsValues = {
                    error: status === incorrect && !minorError && !removed,
                    minorError: minorError,
                    timePoint: timePoint?.index === index ? timePoint.value : "",
                    end: index === end,
                    customStatus: customStatus,
                    removed: removed
                }

                const { error: errorVal, minorError: minorErrorVal, timePoint: timePointVal, end: endVal, customStatus: customStatusVal } = settingsValues

                if (renderData)
                    return (
                        <WrapWordContent key={index}>
                            <WordItem >
                                {markOption &&
                                    <WordSettings
                                        index={index}
                                        word={word}
                                        settingsValues={settingsValues}
                                    />}
                                {displayResults &&
                                    <WordSettings
                                        index={index}
                                        word={word}
                                        settings={displayResultsSettings}
                                    />}
                                <WrapWord key={index}>

                                    <WrapWordText>
                                        <Word $removed={removed} $customStatus={customStatusVal} $minorError={minorErrorVal} $error={errorVal}>
                                            {transcription || word_def_text}
                                        </Word>
                                    </WrapWordText>
                                </WrapWord>


                            </WordItem>
                            {markOption &&
                                <WrapAddWord>
                                    <IconButton icon={<Add fontSize="small" />} size="small" width={30} height={30} />
                                </WrapAddWord>
                            }
                            {break_line && <div style={{ width: "100%" }} />}
                        </WrapWordContent>
                    )
            })}
        </WrapWordList>
    )
}

export default WordList