
import { React, useEffect, useRef, useState } from 'react'
import { DownloadRounded, Pause, PlayArrowSharp, PlayCircleFilled } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useSelector } from 'react-redux';
import { WrapRecord, ChangeAudioSetting, WrapActions, WrapWavesurferPlayer, BasicRecord, Action } from './Style'
import { secondary, thirdly } from 'styles/theme';
import { RecordCrud, RecordData } from './hooks';
import { Progress, ChangeSetting } from 'components/Record'

import './App.css'

const Record = (props) => {
    const { autoplay, stopAudioAfterSeconds, showTimeline, height = 50, id = "basic-record", hidden, changeSetting } = props

    const [isReady, setIsReady] = useState(false)

    const { assessment } = useSelector(state => state.assessment)
    const { isPlaying, rate, zoomIn, wavesurfer } = useSelector(state => state.wavesurfer)
    const { onPlayPause, onPause, handleWSMount, downloadAudio } = RecordCrud()
    const { plugins } = RecordData()

    const pathAudioRef = useRef(null);

    useEffect(() => {
        // Add event listener for spacebar key press
        const handleKeyPress = (event) => {
            if (event.code === 'Space') {
                event.preventDefault();
                onPlayPause();
            }
        };

        document.addEventListener('keydown', handleKeyPress);
        wavesurfer && wavesurfer.on('seeking', () => {
            onPause()
        })

        wavesurfer?.on('ready', () => {
            setIsReady(true)
        });

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            wavesurfer?.pause()
        };
    }, [isPlaying, wavesurfer, zoomIn, rate]);


    return (
        <WrapRecord $hidden={hidden} $basic={true} $changeSetting={changeSetting}>
            {assessment.audio_file ?
                <>
                    {!isReady && <Progress />}
                    {isReady && changeSetting && <ChangeSetting />}
                    <ChangeAudioSetting>
                        {isReady &&
                            <Action onClick={downloadAudio}>
                                {<DownloadRounded />}
                            </Action>}
                        <BasicRecord $hidden={!isReady} id={id} className='basic-record' />
                        {isReady && <Action color='gray' onClick={onPlayPause}>
                            {isPlaying ? <PlayArrowSharp /> : <Pause />}
                        </Action>}
                    </ChangeAudioSetting>

                    <WrapWavesurferPlayer
                        container={`#${id}`}
                        autoplay={autoplay}
                        minPxPerSec={zoomIn * 10}
                        height={height}
                        plugins={showTimeline && plugins}
                        audioRate={1}
                        normalize={true}
                        progressColor={thirdly}
                        waveColor={"#757575"}
                        url={assessment.audio_file}
                        key={pathAudioRef.current}
                        rtl={true}
                        onMount={(surfer) => handleWSMount(surfer, stopAudioAfterSeconds)}
                    />
                </>
                :
                <Progress />
            }
        </WrapRecord >
    )
}

export default Record
