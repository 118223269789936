import React from 'react';
import { keyBy } from 'lodash';
import { Toolbar, NoData, ColumnMenu } from 'components/DataGrid';
import { WrapDataGridTable } from './Style'
import { dataGrid, dataGridModel } from 'keys';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'store';
import './print.css'
import PermissionHOC from 'components/Permissions/PermissionHOC';

const CustomDataGrid = (props) => {
    const { sortModel, fields, rowId = "id", loading, visibilityModelKey, ColumnMenuItem, data, onRowSelectionModelChange, checkboxSelection } = props
    const { visibilityModel } = useSelector(state => state.dataGrid)
    const dispatch = useDispatch()

    const onSortModelChange = (newSortModel) => {
        if (newSortModel.length) {
            const { field } = newSortModel?.[0]
            const mappingFields = keyBy(fields, "field")
            mappingFields[field].sort?.(newSortModel?.[0])
        }
    }

    const serialCol = {
        width: 50, field: "index", headerName: "#", filterable: false, headerAlign: 'center', align: 'center',
        renderCell: (params) => params.api.getAllRowIds().indexOf(params.row[rowId]) + 1
    }

    const sortComparator = (v1, v2, param1, param2) => {
        if (v1 === v2) {
            return param1.row.lastName.localeCompare(param2.row.lastName);
        }
        return v1.localeCompare(v2);
    };

    return (
        // data ?
        <WrapDataGridTable
            permissionHOC="gridColumn"
            loading={!data}
            rows={data || []}
            columns={[serialCol, ...fields]}
            disableSelectionOnClick
            localeText={dataGrid}
            getRowId={(row) => row[rowId]}
            checkboxSelection={checkboxSelection}
            onRowSelectionModelChange={onRowSelectionModelChange}
            columnVisibilityModel={visibilityModel?.[visibilityModelKey] || {}}
            onColumnVisibilityModelChange={(newModel) => {
                dispatch(actions.setVisibilityModel({ key: dataGridModel[visibilityModelKey], value: newModel }))
            }}
            slots={{
                columnMenu: ColumnMenu,
                toolbar: Toolbar,
                noRowsOverlay: NoData,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                },
                pagination: {
                    labelRowsPerPage: "מספר השורות לעמוד"
                }
            }}
            onSortModelChange={onSortModelChange}
        />
        // :
        // <PlaceHolder />
    );
}


export default PermissionHOC(CustomDataGrid)
