import { Box, LinearProgress, linearProgressClasses, List } from '@mui/material'
import styled, { css } from 'styled-components'
import { styled as muiStyled } from '@mui/material/styles';
import { bg, black, dark, thirdly3, white } from 'styles/theme'
import { Center, Disabled, Hover, Link, Scroll } from 'styles/GlobalCss';
import { FlexBetween } from 'styles/GlobalStyle';

const FormCss = css`
${Scroll};
width:430px;
max-height:400px;
overflow-y: auto;
overflow-x: hidden;
padding: 10px;
box-sizing: border-box;
display: flex;
flex-direction: column;
row-gap: 12px;
`

const SubTitle = styled.div`
font-size: 20px;
font-weight: 700;
margin-bottom:10px;

`

const WrapFooterStep3 = styled(FlexBetween)`

`

const WrapHeader = styled.div`
height:30px;
width: 100%;
display: flex;
justify-content: end;
box-sizing: border-box;
padding: 15px;
height: 50px;
text-align: left;
color:${dark};
text-decoration: underline;
font-size:18px;

`

const WrapRegister = styled.div`
display:flex;
flex-direction: column;
align-items:center;
height:100%;
background:${bg};
box-sizing: border-box;
`

const WrapButton = styled.div`
height: 70px;
${Center};
`

const WrapCards = styled.div`
display:flex;
column-gap:20px;
`

const Card = styled.div`
border-radius: 25px;
border: 1px solid #CADDEA;
background: ${white};
width: 278px;
height: 275px;
flex-shrink: 0;
padding: 30px;
box-sizing: border-box;
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;

${props => props.$disabled && Disabled};
`

const WrapPrices = styled.div`
display:flex;
height: 100%;
`

const Actions = styled.div`

`

const WrapRegister1 = styled.div`
${Center};
height:100%;
row-gap: 13px;
flex-direction: column;
`

const WrapForm = styled.div`
display:flex;
align-items:center;
justify-content:center;
height:85%;
position: relative;
width:800px;
column-gap:50px;

@media only screen and (min-height: 550px) {
top: 50px;
}
`

const WrapProgress = styled(Box)`
display:flex;
flex-direction: column;
justify-content:center;
width:800px;

`

const Title = styled.div`
color: #000;
font-size: 23px;
`

const StepTitle = styled.div`
color:${black};
margin-bottom:30px;
font-weight: 400;
font-size: 20px;
`

const WrapSteps = styled.div`
// display:flex;
// justify-content:end;
width:430px;
`

const WrapCartoon = styled.div`
display:flex;
justify-content:start;
`

const CartoonItem = styled.div`
width: 350px;
height: 350px;
`

const GridStep1 = styled.div`
${FormCss};
display: grid;
grid-gap: 12px;
grid-template-areas:
"first_name first_name first_name last_name last_name last_name"
// "gender gender gender gender gender gender"
"email email email email email email"
"email_tooltip email_tooltip email_tooltip email_tooltip email_tooltip email_tooltip"
"next next next next next next"
`

const EmailAvailable = styled.div`
width: 100%;
font-size: 15px;
text-decoration: underline;
color:${dark};
position: relative;
right: 272px;
${Hover}
`

const GridStep2 = styled.div`
${FormCss};
display: grid;
grid-gap: 12px;
grid-template-areas:
"username"
"password"
"next"
`

const GridStep3 = styled.div`
${FormCss};
display: grid;
grid-gap: 12px;
grid-template-areas:
"num_children"
"next"
`

function createChildDetailsCSS(numChildren) {
  let styles = '';

  for (let i = 1; i <= numChildren; i += 1) {
    styles += `
      "child__first_name_${i} child__first_name_${i} child__last_name_${i} child__last_name_${i}  child__gender_${i}  child__gender_${i}"
      "child__grade_${i} child__grade_${i} child__grade_${i} child__remove_btn_${i} child__remove_btn_${i} child__remove_btn_${i}"
      "child__divider_${i} child__divider_${i} child__divider_${i} child__divider_${i} child__divider_${i} child__divider_${i}"
      `
  }

  return css`${styles}`;
}


const GridStep33 = styled.div`
display: grid;
${Scroll};
overflow:auto;
padding:3.5px;
grid-gap: 12px;
grid-template-areas:
${props => createChildDetailsCSS(props.numChildren)};

._child__remove_btn{
border: 1px solid ${thirdly3} !important;

background: ${bg} !important;
}

.MuiSvgIcon-fontSizeSmall{
font-size:16px !important;
}
`

function createFullChildDetailsCSS(numChildren) {
  let styles = '';

  for (let i = 1; i <= numChildren; i += 1) {
    styles += `
      "child__first_name_${i} child__first_name_${i} child__last_name_${i} child__last_name_${i} child__gender_${i} child__gender_${i}"
      "child__tz_${i} child__tz_${i} child__tz_${i} child__db_${i} child__db_${i} child__db_${i}"
      "child__school_${i} child__school_${i} child__school_${i} child__school_${i} child__school_${i} child__school_${i}" 
      "child__grade_${i} child__grade_${i} child__t_class_${i}  child__t_class_${i} child__remove_btn_${i} child__remove_btn_${i}"
      "child__divider_${i} child__divider_${i} child__divider_${i} child__divider_${i} child__divider_${i} child__divider_${i}"
      `
  }

  return css`${styles}`;
}

const GridStep4 = styled.div`
display: grid;
${Scroll};
overflow:auto;
padding:3.5px;
grid-gap: 12px;
grid-template-areas:
${props => createFullChildDetailsCSS(props.numChildren)};

._child__remove_btn{
border: 1px solid ${thirdly3} !important;

background: ${bg} !important;
}

.MuiSvgIcon-fontSizeSmall{
font-size:16px !important;
}
`

const WrapStep33Fields = styled.div`
${FormCss};
`

const WrapStep4Fields = styled.div`
${FormCss};
`

const AddChild = styled.div`
${FormCss};
width:100%;
display: grid;
grid-gap: 12px;
grid-template-areas:
"first_name last_name"
"tz dob"
"school school"
"grade t_class"
`

const AddChildCss = css`
${FormCss};
width:100%;
display: grid;
grid-gap: 12px;
grid-template-areas:
"first_name last_name"
"tz dob"
"school school"
"grade t_class"
`

const WrapStepFields = styled.div`
._first_name{
  grid-area: first_name;
}
._last_name{
  grid-area: last_name;
}
._gender{
  grid-area: gender;
}
._email{
  grid-area: email;
}
_email_tooltip:{
  grid-area: email_tooltip;
}  
._next{
grid-area:next;
}
._username{
grid-area:username;
}
._password{
grid-area:password;
}
._tz{
grid-area:tz;
}
._dob{
grid-area:dob;
}
._school{
grid-area:school;
}
._grade{
grid-area:grade;
}
._t_class{
grid-area:t_class;
}
._view_children{
grid-area:view_children;
}
._next{
grid-area:next;
}
._verification_email{
grid-area:verification_email;
}
._add_child{
grid-area:add_child;
}
._child__gender{
grid-area:child__gender;
}
._child__first_name{
grid-area:child__first_name;
}
._child__last_name{
grid-area:child__last_name;
}
._child__grade{
grid-area:child__grade;
}
._num_children{
grid-area:num_children;
}
._child__divider{
grid-area:child__divider
}
`

const BorderLinearProgress = muiStyled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  transform: "scaleX(-1)",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#F6F6F6",
    ...theme.applyStyles('dark', {
      backgroundColor: "#F6F6F6",
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {

    borderRadius: 5,
    backgroundColor: dark,
    ...theme.applyStyles('dark', {
      backgroundColor: '#308fe8',
    }),
  },
}));

const WrapChildrenList = muiStyled(List)({
  display: 'flex',
  alignItems: 'center',
  rowGap: 10,
  flexDirection: 'column',
  width: '100%'
});

export { AddChild, AddChildCss, EmailAvailable, WrapFooterStep3, GridStep4, WrapStep4Fields, WrapStep33Fields, WrapChildrenList, Title, GridStep33, SubTitle, WrapRegister1, WrapPrices, WrapButton, WrapHeader, BorderLinearProgress, WrapCards, Card, WrapProgress, WrapCartoon, CartoonItem, WrapForm, GridStep3, GridStep2, WrapStepFields, WrapRegister, WrapSteps, StepTitle, GridStep1, Actions }