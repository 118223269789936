import React, { useEffect, useRef } from 'react'
import { AppRoutes } from 'components/Router'
import { Header, Snackbar, SideBar } from 'components'
import { MainCrud } from './hooks'
import { WrapContent, Wrap } from './Style'
import { WrapScreen } from 'styles/GlobalStyle'
import { WrapAlert } from 'components/Alerts'
import { useSelector } from 'react-redux'
import { audioTranscriptionRQ, pulldowns, StoreCrud, application } from 'store/hooks'
import { isEqual } from 'lodash'
import { useDispatch } from 'react-redux'
import { actions } from 'store'

const Main = () => {
    const { init } = MainCrud()
    const { getTestTaker } = StoreCrud()

    const { testTaker } = useSelector(state => state.testTaker)


    const setAppData = application.useInitAppData()
    const cleanAppData = application.useCleanAppData()

    const previousTestTaker = useRef(testTaker)
    const dispatch = useDispatch()
    const { } = audioTranscriptionRQ.useGetInit()


    const getTestTakerOnChange = async () => {
        if (testTaker?.test_taker_id && !isEqual(testTaker, previousTestTaker.current)) {
            const nTestTaker = await getTestTaker(testTaker.test_taker_id)
            previousTestTaker.current = nTestTaker
            dispatch(actions.setTestTaker(nTestTaker))
        }
    }

    useEffect(() => {
        setAppData()
        init()
    }, [])

    useEffect(() => { getTestTakerOnChange() }, [testTaker])

    useEffect(() => {
        return (() => {
            cleanAppData()
        })
    }, [])

    return (
        <WrapScreen>
            <WrapAlert />
            <Snackbar />
            <SideBar />
            <Header />

            <Wrap>
                <WrapContent>
                    <AppRoutes />
                </WrapContent>
            </Wrap>
        </WrapScreen>
    )
}

export default Main


