import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { toastConfig } from "config"
import { isVerifyEmailFetch, validateEmailFetch, validateTzFetch, validateUsernameFetch } from "store/middlewares/auth.crud"


const useIsVerifyEmail = () => {
    return useMutation({
        mutationFn: isVerifyEmailFetch,
        onError: (err) => {
            if (err?.response?.data?.error === 'Email verification required before registration.')
                toast.error("לפני שממשיכים נדרש אימות כתובת מייל", toastConfig)
        }
    })
}

const useValidateUsername = () => {
    return useMutation({
        mutationFn: validateUsernameFetch,
        onError: (err) => {
            if (err?.response?.data?.error === 'username already exists in CustomUsers')
                toast.error("שם משתמש קיים במערכת", toastConfig)
        }
    })
}

const useValidateEmail = () => {
    return useMutation({
        mutationFn: validateEmailFetch,
        onError: (err) => {
            if (err?.response?.data?.error === 'email already exists in CustomUsers')
                toast.error("מייל קיים במערכת", toastConfig)
        }
    })
}

const useValidateTz = () => {
    return useMutation({
        mutationFn: validateTzFetch,
        onError: (err) => {
            if (err?.response?.data?.error === "Tz already exists in TestTakers")
                toast.error('מספר ת"ז קיים במערכת', toastConfig)
        }
    })
}

export default { useValidateTz, useIsVerifyEmail, useValidateUsername, useValidateEmail }