import React, { useEffect } from 'react'
import { Discount, WrapDiscount, Feature, CardFooter, WrapPrices, WrapContentPadding, Description, WrapFeatures, WrapPrice, SubPrice, Price, CardContent, WrapCards, SubTitle, Title, CardHeader } from './Style'

import PricesHooks from './hooks'
import { Card, CardTitle } from './Style'
import { Divider, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { CheckCircleRounded } from '@mui/icons-material'
import { Button } from 'components/Form'
import { Space } from 'styles/GlobalStyle'
import { updateType } from 'keys'

const Prices = (props) => {
    const { disabled, height, button, title, index, registerForm, registerLoading, discount, features, subTitle, description, price, subPrice } = props

    const paymentBtn = PricesHooks.usePayment()

    // const testTakers = registerForm?.children?.filter(testTaker => testTaker.update_type === updateType.create)

    // const labelBtn = button.label(price, registerForm.balance?.id)
    const labelBtn = button.label(price, 1)

    return (
        <Card height={height} key={index} $disabled={disabled}>
            <CardHeader>
                <WrapDiscount discount={discount}>
                    <Discount>
                        {discount.label}
                    </Discount>
                </WrapDiscount>
                <CardTitle>
                    {title}
                </CardTitle>
                {subTitle}
                <Space />
            </CardHeader>
            <CardContent>
                <WrapContentPadding>
                    <WrapPrice>
                        <Price>
                            {`₪${price}`}
                        </Price>
                        <SubPrice>
                            {subPrice}
                        </SubPrice>
                    </WrapPrice>
                    <Description>
                        {description}
                    </Description>
                </WrapContentPadding>
                <WrapFeatures>
                    {features.map(feature =>
                        <>
                            <MenuItem sx={{ height: "25px" }}>
                                <ListItemIcon>
                                    <CheckCircleRounded color="primary2" />
                                </ListItemIcon>
                                <ListItemText>
                                    {feature.label}
                                </ListItemText>
                            </MenuItem>
                            <Divider />
                        </>
                    )}
                </WrapFeatures>
                <CardFooter $disabled={disabled}>
                    <Button
                        {...paymentBtn}
                        label={labelBtn}
                        action={() => paymentBtn.action(props)}
                        loading={registerLoading}
                    />
                </CardFooter>
            </CardContent>
        </Card>
    )
}

export default Prices