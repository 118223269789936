import { useQuery, useQueryClient } from "react-query"
import { queryClient as qc } from 'keys'
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { actions } from "store"
import { useParams } from "react-router-dom"
import { isEmpty } from "lodash"
import { getRatingFetch } from "store/middlewares/rating.crud"

const useGetRating = () => {
    const dispatch = useDispatch()
    const { assessment } = useSelector(state => state.assessment)

    return useQuery(qc.rating,
        {
            queryFn: ({ signal }) => getRatingFetch({ id: assessment.id, signal, }),
            enabled: !!assessment.id,
            onSuccess: (res) => {
                const ratingData = res.data
                const { semester, reading_assessment_name } = ratingData
                dispatch(actions.updateAssessment({ semester: semester, reading_assessment_name: reading_assessment_name }))
                dispatch(actions.setRating(ratingData.rating))
            }
        })
}

export default { useGetRating }