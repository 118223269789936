import { useSelector } from 'react-redux';
import { semesters } from 'keys';
import { assessmentUtils } from 'helpers';
import { actions } from 'store';
import { useDispatch } from 'react-redux';

const useHeaderItems = () => {
    const dispatch = useDispatch()
    const { assessmentDefs, assessmentFilters } = useSelector(state => state.assessment)

    const handleChangeAssessmentsFilters = (key, value) => {
        const nAssessmentFilters = { ...assessmentFilters, [key]: value }
        dispatch(actions.setAssessmentFilters(nAssessmentFilters))
    }

    const headerItems = [
        {
            id: 1,
            placeholder: "שנה",
            options: [{ id: 2024, name: "2024" }],
            name: "year",
            input: {
                value: assessmentFilters?.year,
                onChange: (value) => handleChangeAssessmentsFilters('year', value)
            },
            component: "autoComplete",
            width: 100
        },
        {
            id: 2,
            placeholder: "סמסטר",
            options: semesters,
            name: "semester",
            input: {
                value: assessmentFilters?.semester,
                onChange: (value) => handleChangeAssessmentsFilters('semester', value)
            },
            component: "autoComplete",
            width: 200
        },
        {
            id: 3,
            placeholder: "מבדק",
            options: assessmentDefs,
            name: "assessment_def",
            input: {
                value: assessmentFilters?.assessment_def || [],
                onChange: (value) => {
                    handleChangeAssessmentsFilters('assessment_def', value || [])
                }
            },
            component: "autoComplete",
            multiple: true,
            width: 250
        },
        // {
        //     id: 4,
        //     label: "ניקוי",
        //     name: "clear",
        //     action: cleanAssessmentFilters,
        //     component: "button",
        //     height: '42px',
        //     width: '80px',
        //     color: "secondary"
        // },
    ]

    return headerItems
}

export default useHeaderItems