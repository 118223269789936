import React from 'react'
import { WrapChallenges, WrapChallengeItem } from './Style'
import { RatingData } from './hooks'
import { Button, fieldsTyps } from 'components/Form'

const Challenges = () => {
    const { challenges } = RatingData()

    return (
        <WrapChallenges>
            {challenges.map((challenge, index) => {
                const Component = fieldsTyps[challenge.component]
                return (
                    <WrapChallengeItem key={index}>
                        <Component {...challenge} />
                    </WrapChallengeItem>)
            }

            )}
        </WrapChallenges>
    )
}

export default Challenges