import React from 'react'
import { useSelector } from 'react-redux'
import { map } from 'lodash'
import { WrapContentSection, WrapRating, WrapActions, Rate } from './Style'
import { Button } from 'components/Form'
import { RatingData } from './hooks'

const Rating = () => {
    const { actions } = RatingData()
    const { scoreRating } = useSelector(state => state.rating)

    return (
        <WrapContentSection>
            <WrapRating>
                {map(scoreRating).map((rate => {
                    const { rating, id } = rate
                    return (
                        <Rate key={id} {...rate}>
                            {rating}
                        </Rate>
                    )
                }
                ))}
            </WrapRating>
            <WrapActions>
                {actions.map(actionItem => {
                    const { id } = actionItem

                    return (
                        <Button
                            color="dark"
                            $fullWidth
                            key={id}
                            {...actionItem}
                        />
                    )
                })}
            </WrapActions>
        </WrapContentSection>
    )
}

export default Rating