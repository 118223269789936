import React, { useEffect } from 'react';
import { Header } from './Style';
import { fieldsTyps } from 'components/Form';
import { WrapHeader } from 'styles/GlobalStyle'

import AssessmentsHooks from 'components/School/Assessments/hooks';

const Assessments = (props) => {
    const headerItemsP = AssessmentsHooks.useHeaderItems()
    const { headerItems = headerItemsP, headercss } = props

    useEffect(() => {
        headerItems.forEach((item) => {
            if (!item.input.value && item.defaultValue) {
                console.log(item)
                item.input.onChange(item.defaultValue)
            }
        })
    }, [headerItems])

    return (
        <WrapHeader>
            <Header headercss={headercss}>
                {headerItems.map((headerBtn, index) => {
                    let Component = fieldsTyps[headerBtn.component]

                    return (
                        <Component
                            key={index}
                            fullwidth
                            {...headerBtn}
                            input={{
                                ...headerBtn.input,
                                value: headerBtn.input.value
                            }}
                        />
                    )
                })}
            </Header>
        </WrapHeader>
    );
}

const isRender = (prev, next) => {
    return false
}

export default React.memo(Assessments, isRender)
