import React from 'react'
import { Navigate } from "react-router-dom";
import { app } from './routes';
import { secretStorage } from 'config';

const PrivateRoutesAuth = (props) => {
    const token = secretStorage.getItem("user")?.token;
    const isAuth = !!token; // Assuming getToken() returns truthy value when token is present

    if (isAuth)
        return <Navigate to={app.app} replace />

    return props.children
}

export default PrivateRoutesAuth