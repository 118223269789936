import { useContext } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { toastConfig } from 'config'
import { checkCouponCodeFetch } from 'store/middlewares/auth.crud'
import { app } from 'components/Router/routes'
import { PaymentContext } from '../PaymentContext'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { actions } from 'store'
import { useNavigate } from 'react-router-dom'
import { secretStorage } from 'config'
import constants from './constants'

const usePayment = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { couponCode } = useContext(PaymentContext)

    const { mutateAsync: checkCouponCode } = useMutation({
        mutationFn: checkCouponCodeFetch,
        onError: (error) => {
            if (error?.response?.data?.error === 'Coupon has already been used')
                toast.error('קוד קופון אינו בתוקף', toastConfig)
            else toast.error("קוד קופון אינו קיים", toastConfig)
        }
    })

    const payment = async ({ registerForm }) => {
        const formValues = secretStorage.getItem("registerForm")
        const secretUser = secretStorage.getItem("user")

        if (secretUser) {
            secretStorage.setItem("user", { ...secretUser, user_id: secretUser.user_id })
            dispatch(actions.setUser({ user_id: secretUser.user_id }))
        }

        const secretData = registerForm ? registerForm : { ...formValues, new_balance: 1 }
        secretStorage.setItem("registerForm", secretData)

        const paymentLink = constants.paymentLinks[registerForm?.balance?.id || 1]

        if (paymentLink) {
            window.location.href = paymentLink;
        }
    }

    const paymentBtn = {
        color: "thirdly",
        height: "46px",
        width: "215px",
        fontsize: '18px',
        action: payment,
        disabledColor: "thirdly",
        className: "payment_btn"
    }

    return paymentBtn
}

export default usePayment