import Register1 from './Register1'
import Register2 from './Register2'
import Register3 from './Register3'
// import Register4 from './NotInUse/Register4'
import ChildrenList from './NotInUse/ChildrenList'
import RegisterContext from './RegisterContext'
import Step1Fields from './Step1Fields'
import Step2Fields from './Step2Fields'
import Step3Fields from './NotInUse/Step3Fields'
// import Step4Fields from './NotInUse/Step4Fields'
import NewChild from './NotInUse/NewChild'
import EmailVarification from './EmailVarification'
import RegisterSteps from './RegisterSteps'
import EmailTooltip from './EmailTooltip'

export { EmailTooltip, RegisterSteps, EmailVarification, NewChild, ChildrenList, Register3, Register1, Register2, Step1Fields, Step2Fields, Step3Fields }
export default RegisterContext