import { statuses } from 'keys'

const AdminAssessmentsData = () => {
    const { inProgress, brokenFile, wordsComplete, wordsInProgress, uploaded, backToMarking } = statuses

    return [
        {
            id: 0,
            label: "ממתינים לסימון",
            status: [uploaded, backToMarking],
            sub_status: ""
        },
        {
            id: 1,
            label: "בסימון",
            status: [inProgress],
            sub_status: wordsInProgress
        },
        {
            id: 2,
            label: "סימון הסתיים",
            status: [inProgress],
            sub_status: wordsComplete
        },
        {
            id: 3,
            label: "אודיו לא תקין",
            status: [brokenFile],
            sub_status: ""
        }
    ]

}



export default AdminAssessmentsData
