import { useLocation, useNavigate } from 'react-router-dom'
import { app } from 'components/Router/routes'
import { useMutation } from 'react-query'
import { loginFetch } from 'store/middlewares/auth.crud'
import { secretStorage } from 'config'
import { application } from 'store/hooks'
import { toast } from 'react-toastify'
import { toastConfig } from 'config'
import { useDispatch } from 'react-redux'
import { actions } from 'store'

const LoginCrud = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    const setAppData = application.useInitAppData()

    const from = location.state?.from || "/";

    const { mutateAsync: loginUser, isLoading: loginLoading, error } = useMutation(loginFetch, {
        onSuccess: (res) => {
            if (res.data) {
                const user = res.data
                secretStorage.setItem("user", user)
                setAppData(user)
                navigate(from, { replace: true });
            }
        },
        onError: (error) => {
            const { response } = error
            if (response.status === 401) {
                toast.error("שם משתמש או סיסמה שגוי", toastConfig);
            }
            else if (response.status === 400) {
                const user_id = response.data.user_id
                toast.error("חסרים לנו פרטים על הילד.ים שלך", toastConfig)
                const secretUser = secretStorage.getItem("user")
                secretStorage.setItem("user", { ...secretUser, user_id: user_id })
                dispatch(actions.setUser({ user_id: user_id }))
                navigate(app.register4)
            }
            else toast.error("שגיאה, נסה שוב מאוחר יותר", toastConfig);

            return error
        }
    })

    const login = async (values) => {
        await loginUser(values)
    }

    return {
        login,
        loginLoading
    }
}

export default LoginCrud