import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { orderBy, pick } from 'lodash';
import { app } from 'components/Router/routes'
import { actions } from 'store'
import { loading } from 'keys';
import { useQueryClient } from 'react-query';

const AssessmentsAndTestTakersCrud = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const { assessments, assessmentFilters } = useSelector(state => state.assessment)
    const { columnItems } = useSelector(state => state.general)
    const { selectedTestTakers } = useSelector(state => state.testTaker)

    const updateCurrentAssessmnet = async (item) => {
        dispatch(actions.setRating({}))
        dispatch(actions.setTestTaker({ test_taker_id: item.test_taker_id }))
    }

    const goAllAssessmnets = async (item) => {
        const { id, test_taker_id } = item
        dispatch(actions.setAssessment({ id: id }))
        await updateCurrentAssessmnet(item)
        navigate(app.assessments, {
            // state: {
            //     test_taker_id: test_taker_id
            // }
        })
    }

    const goRating = async (item) => {
        const { id } = item
        const assessmentSerializer = ["audio_file_url", "id"]
        dispatch(actions.setAssessment(pick(item, assessmentSerializer)))
        updateCurrentAssessmnet(item)
        navigate(`${app.assessments}/${id}`, {
            state: {
                id: id
            }
        })
    }

    // const assessmentGroup =
    //     chain(assessments)
    //         .groupBy("test_taker_id").value()
    // .map((value, key) => ({ test_taker_id: key, assessments: value }))

    const sortNumbers = (sortModel, sortKey) => {
        const { sort } = sortModel

        let nAssessments = orderBy(assessments,
            [function (assessment) {
                const { rating = {} } = assessment
                return rating[sortKey]
            }], [sort]);

        dispatch(actions.setAssessments(nAssessments))
    }

    const sortByRateAndAccuracy = (sortModel, sortKey, qcName) => {
        const { sort } = sortModel

        queryClient.setQueryData([qcName], oldData => {
            return {
                ...oldData,
                data: orderBy(oldData.data,
                    [function (assessment) {
                        const { rating = {} } = assessment
                        return rating[sortKey]
                    }], [sort])
            }
        });
    }

    const handleChangeAssessmentsFilters = (key, value) => {
        const nAssessmentFilters = { ...assessmentFilters, [key]: value }
        dispatch(actions.setAssessmentFilters(nAssessmentFilters))
    }

    const goTestTakers = () => {
        navigate(app.testTakers)
    }

    const goAssessmentDefs = () => {
        navigate(app.makingRecordAssessments)
    }

    const createNewAssessment = (item) => {
        updateCurrentAssessmnet(item)
        navigate(app.createAssessmentRecord)
    }

    const cleanAssessmentFilters = () => {
        dispatch(actions.cleanAssessmentFilters())
    }

    const updateColumnItems = (fields) => {
        let nColumnItems = {}
        fields.forEach(field => {
            nColumnItems[field] = !columnItems[field]
        });
        dispatch(actions.updateColumnItems(nColumnItems))
    }

    const printAssessments = async () => {
        try {
            dispatch(actions.setLoading({ [loading.printAssessment]: true }))
            const pdfFile = await dispatch(actions.printAssessment(selectedTestTakers))
            window.open(pdfFile, "PRINT", "height=400,width=600");
            dispatch(actions.setLoading({ [loading.printAssessment]: false }))
        }
        catch {
            window.open(require('mock/Try.pdf'), "PRINT", "height=400,width=600");

            dispatch(actions.setLoading({ [loading.printAssessment]: false }))
            console.log("error printAssessment")
        }
    }

    return {
        goRating,
        cleanAssessmentFilters,
        updateColumnItems,
        goAssessmentDefs,
        printAssessments,
        handleChangeAssessmentsFilters,
        goTestTakers,
        goAllAssessmnets,
        sortByRateAndAccuracy,
        sortNumbers,
        updateCurrentAssessmnet,
        createNewAssessment,
    }
}

export default AssessmentsAndTestTakersCrud