import React from 'react'
import { useSelector } from 'react-redux'
import { isEmpty, pick } from 'lodash'
import { WrapIndication, IndicationLabel } from './Style'
import { naLabel, ratingListLabel, ratingListTheme } from 'keys/rating'
import { gTheme } from 'styles/theme'
import { statuses } from 'keys'

const RateAndAccuracy = (props) => {
    const { value } = props
    const { fields, rating, status } = value || {}
    const { field, precent, index } = fields || {}

    const ratingFields = pick(rating, [index])
    let ratingIndex = ratingFields[index]
    let currentScoreRatingMethod

    if (status === statuses.brokenFile)
        currentScoreRatingMethod = {
            label: naLabel
        }

    else {
        currentScoreRatingMethod = {
            themeColor: ratingListTheme?.[ratingIndex - 1],
            label: rating?.[field] ? `${rating?.[field]}${precent ? "%" : ""}` : ""
        }
    }

    const { label, themeColor } = currentScoreRatingMethod

    return (
        <WrapIndication>
            <IndicationLabel color={gTheme?.[themeColor]?.main || 'ligthGray'}>
                {label && `${label}`}
            </IndicationLabel>
        </WrapIndication>
    )

}

export default RateAndAccuracy