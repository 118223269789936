import React from 'react'
import { validate } from 'components/School/CreateAffiliation'
import { Alert } from 'components'
import { WrapCreateAffiliation } from './Style'
import { Form } from 'components/Form'
import { AffiliationData, AffiliationCrud } from './hooks'
import { useSelector } from 'react-redux'
import { pulldowns } from 'store/hooks'

const Affliation = () => {
    const { formName, fields, title, submitBtns } = AffiliationData()
    const { handleCreateAffiliationAlert, createAffiliation } = AffiliationCrud()
    const { headerFilters } = useSelector(state => state.header)

    return (
        <Alert title={title} handleAlert={handleCreateAffiliationAlert}>
            <Form
                form={formName}
                title={null}
                validations={validate}
                fields={fields}
                csswrap={WrapCreateAffiliation}
                submitBtns={submitBtns}
                submit={createAffiliation}
                initialValues={headerFilters}
            />
        </Alert>
    )
}

export default Affliation