import React from 'react'
import { WrapContactUs, ContactUsCss } from './Style'
import { Button, Form } from 'components/Form'
import { CartoonItem, WrapCartoon, WrapForm, WrapSteps } from 'components/Auth/Register/Style'
import { FlexCenter, Img } from 'styles/GlobalStyle'

import Go3 from 'assets/Go3.png'
import contactUs from './hooks'
import validate from './Validate'

const ContactUs = () => {
    const { formname, title } = contactUs.constants

    const fields = contactUs.useFields()
    const submitBtn = contactUs.useSubmitBtn()

    return (
        <FlexCenter>
            <WrapForm>
                <WrapSteps>
                    <Form
                        title={title}
                        csswrap={ContactUsCss}
                        fields={fields}
                        submit={() => { }}
                        form={formname}
                        validations={validate}
                        submitBtns={[submitBtn]}
                    />
                </WrapSteps>
                <WrapCartoon>
                    <CartoonItem>
                        <Img src={Go3} />
                    </CartoonItem>
                </WrapCartoon>
            </WrapForm>
        </FlexCenter>
    )
}

export default ContactUs