import { useDispatch, useSelector, } from "react-redux"
import { actions } from 'store'
import { useRef, useCallback } from "react"
import { getDecodedFilenameFromUrl } from "utils/formatter"

const RecordCrud = () => {
    const { wavesurfer, isPlaying, regions } = useSelector(state => state.wavesurfer)
    const { assessment } = useSelector(state => state.assessment)

    const wavesurferRef = useRef()
    const stopAudioAfterSecondsRef = useRef()

    const regionsRef = useRef(regions);
    regionsRef.current = regions;
    const dispatch = useDispatch()

    const handleWSMount =
        useCallback(
            (waveSurfer, stop) => {
                wavesurferRef.current = waveSurfer;

                if (wavesurferRef.current) {
                    dispatch(actions.setWavesurfer(wavesurferRef.current))
                    dispatch(actions.setIsPlaying(false))

                    wavesurferRef.current.on("ready", () => {
                        console.log("WaveSurfer is ready");
                        setIsPlaying(true);
                    });

                    if (stop)
                        wavesurferRef.current.on('audioprocess', function () {

                            const currentTime = wavesurferRef.current.getCurrentTime()

                            // Check if playback time is greater than or equal to 50 seconds
                            if (Math.floor(currentTime) === stop) {
                                // Execute your logic when playback reaches 50 seconds
                                if (!stopAudioAfterSecondsRef.current) {
                                    wavesurferRef.current.pause()
                                    stopAudioAfterSecondsRef.current = true
                                }
                                // Add your custom actions here
                            }

                        }
                        )

                    if (window) {
                        window.surferidze = wavesurferRef.current;
                    }
                }
            }
            ,
            [assessment, wavesurfer],
        );

    const setIsPlaying = (value) => {
        dispatch(actions.setIsPlaying(value))
    }

    const playPause = () => {
        if (wavesurfer)
            wavesurfer.playPause()
        else wavesurferRef.current.playPause()
    }

    const onPlayPause = (stop) => {
        playPause()
        setIsPlaying(!isPlaying)
    }

    const onPause = () => {
        wavesurferRef.current && wavesurferRef.current.pause()
    }

    const changeDuration = (duration) => {
        wavesurfer.setPlaybackRate(duration)
    }

    const handleZoomIn = (value) => {
        wavesurfer.zoom(value * 10);
    }

    const handleSeeking = () => {
        wavesurfer.skip(-2)
        // wavesurfer.play()
    }

    const downloadAudio = async () => {
        if (assessment.audio_file) {
            const downloadLink = document.createElement('a');
            downloadLink.href = assessment.audio_file
            downloadLink.download = `${getDecodedFilenameFromUrl(assessment.audio_file_url)}.wav`;
            downloadLink.click();
        }
    };

    // const downloadAudio = async () => {
    //     if (assessment.audio_file_url) {
    //         fetch(`${assessment.audio_file_url}/`)
    //             .then(response => response.blob())
    //             .then(blob => {
    //                 const url = window.URL.createObjectURL(new Blob([blob]));
    //                 const link = document.createElement('a');
    //                 link.href = url;
    //                 link.download = "audio.mp3"
    //                 link.click();
    //             })
    //             .catch(error => console.error('Error downloading file:', error));
    //     }
    // };

    return {
        downloadAudio,
        setIsPlaying,
        changeDuration,
        onPlayPause,
        onPause,
        handleWSMount,
        handleZoomIn,
        isPlaying,
        handleSeeking
    }
}

export default RecordCrud