import { AlertCrud } from "components/Alerts/hooks"
import { app } from "components/Router/routes"
import { alerts, updateType } from "keys"
import qc from "keys/queryClient"
import { isEmpty, uniqueId } from "lodash"
import { useCallback } from "react"
import { useQuery } from "react-query"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { arrayPush } from "redux-form"
import { getDashboardFetch } from "store/middlewares/assessment.crud"


const useDashboardCards = () => {
    const navigate = useNavigate()

    const goAllAssessments = () => navigate(app.allAssessments)
    const goAssessments = () => navigate(app.assessments)
    const goPayments = () => navigate(app.payments)

    const { setAlerts } = AlertCrud()

    const { data: dashboardData = {} } = useQuery({
        queryKey: qc.dashboard,
        queryFn: getDashboardFetch,
        retry: 0,
        onSuccess: (data) => {
            if (!data.test_taker_count)
                openTestTakerPopup()
        }
    })

    const openTestTakerPopup = useCallback(() => {
        setAlerts(alerts.createTestTaker, true)
    }, [])

    const noTestTakers = dashboardData.test_taker_count === 0
    const loading = isEmpty(dashboardData)

    const cards = [
        {
            id: 0,
            loading: loading,
            label:
                noTestTakers ?
                    "אין לנו את פרטי הילדים שלכם במערכת" :
                    `יש לכם ${dashboardData.test_taker_count} ילדים במערכת`,
            button: {
                label: "להוספת ילד",
                color: noTestTakers ? "secondary" : "light",
                action: openTestTakerPopup
            }
        },
        {
            id: 1,
            loading: loading,
            label: dashboardData && `${dashboardData.created_count} אבחונים מחכים לביצוע`,
            button: {
                label: "לאיזור האבחונים",
                color: noTestTakers ? 'light' : "secondary",
                action: goAllAssessments,
                blink: dashboardData.created_count > 0,
                disabled: !dashboardData.created_count,
                disabledColor: "gray2"
            }
        },
        {
            id: 2,
            loading: loading,
            label: dashboardData && `${dashboardData.marking_completed_count} דוחות חדשים מחכים לך באזור הדוחות`,
            button: {
                label: "לצפיה בדוחות",
                color: dashboardData.marking_completed_count ? "secondary" : 'light',
                action: goAssessments,
                disabled: !dashboardData.marking_completed_count,
                disabledColor: "gray2"
            }
        },
        {
            id: 3,
            label: "מומלץ לבדוק התקדמות כל 3 חודשים",
            button: {
                label: "לרכישת אבחון",
                color: 'light',
                disabled: true,
                action: goPayments,
                disabledColor: "gray2"
            }
        }
    ]

    return cards
}

export default useDashboardCards