import { lightDark, secondary } from "styles/theme"
import { MarkingWordsCrud } from "."
import { statuses } from "keys"
import { useSelector } from "react-redux"
import { isEmpty } from "lodash"

const MarkingWordsData = () => {
    const {
        cleanMarkingWords, markingWordsSaveDraft, markingWordsSaveAndNext, markingWordsFinish, updateBrokenFileStatus,
        markingWordsSaveDraftLoading, saveWordsLoading, markingWordsCompleteLoading, updateBrokenFileStatusLoading
    } = MarkingWordsCrud()

    const { assessment } = useSelector(state => state.assessment)

    const saveDraftLoading = markingWordsSaveDraftLoading || saveWordsLoading
    const completeLoading = saveWordsLoading || markingWordsCompleteLoading
    const completeDisabled = isEmpty(assessment.timePoint) || completeLoading

    const actionBtns = [
        {
            id: 1,
            label: "ניקוי",
            color: "dark",
            action: cleanMarkingWords,
            width: 80,
            height: "35px",
        },
        {
            id: 0,
            label: saveDraftLoading || "שמירה",
            action: markingWordsSaveDraft,
            color: "primary",
            loading: saveDraftLoading,
            width: 110,
            height: "35px",
            disabled: saveDraftLoading,
            disabledColor: "gray2"
        },
        {
            id: 2,
            label: completeLoading || "שמירה ומעבר למבדק הבא",
            action: markingWordsSaveAndNext,
            color: "secondary",
            loading: completeLoading,
            width: 200,
            disabled: completeDisabled,
            themeColor: secondary,
            disabledColor: "gray2",
            height: "35px"
        },
        {
            id: 3,
            label: completeLoading || "סיום",
            action: markingWordsFinish,
            color: "lightDark",
            loading: completeLoading,
            width: 80,
            disabled: completeDisabled,
            themeColor: lightDark,
            disabledColor: "gray2",
            height: "35px",
        }
    ]

    const brokenFile = {
        [statuses.inProgress]: {
            [statuses.wordsInProgress]: {

            },
            [statuses.wordsComplete]: {
            }
        },
        [statuses.uploaded]: {
            "default": {
            }
        },
        [statuses.backToMarking]: {
            "default": {
            }
        },
        [statuses.brokenFile]: {
            "default": {
            }
        }
    }

    const brokenFileProps = brokenFile[assessment.status]?.[assessment.sub_status || "default"]

    const headerActions = [
        {
            label: "אודיו לא תקין",
            color: "dark",
            action: updateBrokenFileStatus,
            loading: updateBrokenFileStatusLoading,
            width: 110,
            component: 'button',
            disabled: assessment?.sub_status != statuses.wordsInProgress,
            hide: brokenFileProps?.hide,
            disabledColor: "gray2"
        }
    ]

    return {
        actionBtns,
        headerActions
    }

}

export default MarkingWordsData
