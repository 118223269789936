import { CopyAll } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { toastConfig } from "config"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { generateCouponCodeFetch } from "store/middlewares/auth.crud"
import { SpaceBetween } from "styles/GlobalStyle"


const CouponToast = ({ coupon_code }) => {
    return (
        <div style={{ display: 'flex', columnGap: "5px",alignItems:"center" }}>
            <div>
                הקוד הוא:
                {" "}
                {coupon_code}
            </div>

            <IconButton onClick={() => navigator.clipboard.writeText(coupon_code)}>
                <CopyAll />
            </IconButton>
        </div>
    )
}

const useGenerateCouponCode = () => {
    const { mutateAsync: generateCouponCode, isLoading: generateCouponCodeLoading } = useMutation({
        mutationFn: generateCouponCodeFetch,
        onSuccess: (res) => {
            toast(<CouponToast coupon_code={res.data.coupon_code} />, toastConfig)
        }
    })

    const generateCouponCodeBtn = {
        id: 0,
        label: "צור קוד קופון",
        name: "report",
        action: generateCouponCode,
        component: "button",
        height: "35px",
        width: '120px',
        color: "rating5",
        disabledColor: 'rating4',
        disabled: generateCouponCodeLoading,
        loading: generateCouponCodeLoading,

        feature: "generate-coupon-code",//TODO move to json features
        role: "create",
        permissionHOC: "hidden",
    }

    return generateCouponCodeBtn
}

export default useGenerateCouponCode