import React from 'react';
import { Checkbox } from '@mui/material';
import { BpIcon, BpCheckedIcon } from './Styled'

const CustomCheckBox = (props) => {
    const { name, label, input = {}, section, className, mandatory } = props,
        { onChange, value } = input;

    return (
        <Checkbox
            name={name}
            checked={value ? true : false}
            onChange={({ target }) => onChange(target.checked)}
            sx={{
                '&:hover': { bgcolor: 'transparent' },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />
    );
}

export default CustomCheckBox