import { ScoreRatingMethodVM } from 'models/VM'
import { actions } from 'store'
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from 'react-router-dom'
import { app } from 'components/Router/routes'
import { ratingRQ } from 'store/hooks'

const ReportData = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const { assessment } = useSelector(state => state.assessment)
    const assessmentID = assessment.id || params?.assessmentID

    const { } = ratingRQ.useGetRating()

    const getClassStats = async () => {
        try {
            const classStats = await dispatch(actions.getClassStats(assessmentID))
            dispatch(actions.setClassStats(classStats.accuracy_marks))
        }
        catch {
            console.log("error getClassStats")
        }
    }

    const cleanData = () => {
        dispatch(actions.setClassStats(null))
    }

    const getScoreRatingMethod = async () => {
        try {
            const rating = await dispatch(actions.getScoreRatingMethod(assessmentID))
            let nRating = {}
            rating.forEach((ratingItem) => {
                nRating[ratingItem.id] = new ScoreRatingMethodVM(ratingItem)
            })
            dispatch(actions.setScoreRating(nRating))
        }
        catch {
            console.log("error getScoreRatingMethod")
        }
    }

    const goWords = () => {
        navigate(`${app.assessmentView}/${assessment.id}`)
    }

    const goMatchLearning = () => {
        navigate(`${app.matchingLearning}/${assessment.id}`)
    }

    const goRating = (id) => {
        dispatch(actions.updateAssessment({ id: id }))
        navigate(`${app.assessments}/${id}`, {
            state: {
                id: id
            }
        })
    }

    const init = async () => {
        getScoreRatingMethod()
        getClassStats()
    }

    return {
        init,
        goRating,
        goMatchLearning,
        goWords,
        getScoreRatingMethod,
        cleanData
    }
}

export default ReportData