import { actions } from 'store'
import { useDispatch, useSelector } from "react-redux"
import { StoreCrud } from 'store/hooks'

const CreateAssessmentCrud = () => {
    const dispatch = useDispatch()
    const { assessmentDefs } = useSelector(state => state.assessment)
    const { getAssessmentDefs } = StoreCrud()

    const init = async () => {
        if (!assessmentDefs) {
            getAssessmentDefs()
        }
    }

    return {
        init
    }
}

export default CreateAssessmentCrud