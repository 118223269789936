import { AssessmentDefsCrud } from "components/AssessmentDefs/hooks"
import { CreateAssessmentFormCrud } from "components/School/CreateAssessment/CreateAssessmentForm/hooks"
import { app } from "components/Router/routes"
import { loading } from "keys"
import { isEmpty } from "lodash"
import { BatteryRM } from "models/RM"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { actions } from "store"
import { StoreCrud } from "store/hooks"

const UploadAudioCrud = () => {
    const { getAssessmentDefs } = StoreCrud()
    const { selectedAssessmentDef } = useSelector(state => state.assessment)
    const { testTaker } = useSelector(state => state.testTaker)
    const { createAssessment } = CreateAssessmentFormCrud()

    const params = useParams()

    const dispatch = useDispatch()

    const init = async () => {
        if (isEmpty(testTaker)) {
            dispatch(actions.editTestTaker({ test_taker_id: params.testTakerID }))
        }
        getAssessmentDefs && getAssessmentDefs()
    }

    const cleanAssessmentForm = () => {
        dispatch(actions.setSelectedAssessmentDef(null))
        dispatch(actions.setAssessment({}))
    }

    const cleanData = () => {
        dispatch(actions.setSelectedAssessmentDef(null))
        dispatch(actions.setSelectedTestTakers([]))
    }

    const updateSelectedAssessmentDef = (val) => {
        dispatch(actions.updateSelectedAssessmentDef({ audio_file: val }))
    }

    const createAssessmentWithFile = async (props) => {
        const { prevSelectedAssessments, setPrevSelectedAssessments } = props
        dispatch(actions.setLoading({ [loading.createAssessmentWithFile]: true }))
        const values = {
            assesment_def: selectedAssessmentDef.id,
            file: selectedAssessmentDef.audio_file,
        }
        try {
            await createAssessment(values)
            dispatch(actions.setLoading({ [loading.createAssessmentWithFile]: false }))
            setPrevSelectedAssessments([...prevSelectedAssessments, selectedAssessmentDef])
            dispatch(actions.setSelectedAssessmentDef({}))
        }
        catch {
            dispatch(actions.setLoading({ [loading.createAssessmentWithFile]: false }))
        }

    }

    return {
        init,
        cleanData,
        updateSelectedAssessmentDef,
        createAssessmentWithFile,
        cleanAssessmentForm
    }
}

export default UploadAudioCrud