import React, { useContext } from 'react'
import { RegisterContext } from './RegisterContext'
import { WrapSteps, WrapCartoon, WrapProgress, CartoonItem, WrapForm, BorderLinearProgress } from './Style'
import { Img } from 'styles/GlobalStyle';
import { register } from './hooks'

const RegisterSteps = (props) => {
    const { children } = props
    const { step } = useContext(RegisterContext)

    const steps = register.useSteps()

    return (
        <>
            <WrapForm>
                <WrapSteps>
                    {children}
                </WrapSteps>
                <WrapCartoon>
                    <CartoonItem>
                        <Img src={steps[step].cartoon} />
                    </CartoonItem>
                </WrapCartoon>
            </WrapForm>
            <WrapProgress>
                <BorderLinearProgress variant="determinate" value={(100 / 5) * (step + 1)} />
            </WrapProgress>
        </>
    )
}

export default RegisterSteps