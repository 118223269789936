import React from 'react'
import Auth from 'components/Auth'
import Validate from './Validate'
import { Form } from 'components/Form'
import { constants, useFields, useSubmit } from './hooks'
import { RegisterLink } from 'components/Auth/Style'
import { app } from 'components/Router/routes'
import { recoverUsernameFetch } from 'store/middlewares/auth.crud'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { toastConfig } from 'config'

const ResetPassword = () => {
    const { backToLogin, formName, title, subTitle } = constants

    const fields = useFields()
    const submitBtns = useSubmit()

    const { mutateAsync: recoverUsername } = useMutation({
        mutationFn: recoverUsernameFetch,
        onSuccess: () => {
            toast('נשלח מייל עם שם המשתמש',toastConfig)
        },
        onError: (error) => {
            if (error?.response?.data?.error === 'User not found')
                toast.error('משתמש לא קיים במערכת', toastConfig)
            else toast.error("שגיאה", toastConfig)
        }
    })

    return (
        <Auth>
            <Form
                form={formName}
                title={title}
                subTitle={subTitle}
                submit={recoverUsername}
                validations={Validate}
                fields={fields}
                submitBtns={submitBtns}
            />
            <RegisterLink to={app.login}>
                {backToLogin}
            </RegisterLink>
        </Auth>
    )
}

export default ResetPassword