import React, { memo } from 'react';
import { Label, Error } from 'components/Form'
import { MenuItem, TextField, Chip, createFilterOptions, Typography, Paper } from '@mui/material'
import { WrapAutocomplete, WrapField, AutoCompleteOptions, WrapFieldContent, WrapMoreOptions } from './Styled'
import { XSSpaceBetween } from 'styles/GlobalStyle';
import { isEmpty, isEqual } from 'lodash';
import { white } from 'styles/theme';

const OPTIONS_LIMIT = 10;
const defaultFilterOptions = createFilterOptions();

const AutoComplete = (props) => {
    const
        { required, hideOption = () => false, gridArea, arrayIndex, noOptionsText, systemUI, createNew, label, width, getOptionLabel, labelKey = "name", input, options = [], disabled, className, placeholder, multiple, mandatory } = props,
        { onChange, value } = input;

    const renderValue = () => {
        if (value && typeof value != 'object') {
            const optionIndex = options?.findIndex((opt) => opt?.id.toString() === value?.toString())
            return options?.[optionIndex] || ""
        }
        return value || null
    }

    const filterOptions = (options, state) => {
        const filteredOptions = defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
        const moreOptions = defaultFilterOptions(options, state).length - OPTIONS_LIMIT;
        return moreOptions > 0 ? filteredOptions.concat({ moreOptions: `${moreOptions} more options` }) : filteredOptions;
    };

    const getLabel = (opt) => getOptionLabel ? getOptionLabel(opt) : opt?.[labelKey]

    return (
        <WrapField className={className} gridArea={`${gridArea}_${arrayIndex}`}>
            <WrapFieldContent>
                {label && <Label label={label} mandatory={mandatory} />}
                <WrapAutocomplete
                    $systemUI={systemUI}
                    filterOptions={filterOptions}
                    // disablePortal
                    id="combo-box-demo"
                    noOptionsText={noOptionsText || 'אין תוצאות'}
                    options={options || []}
                    sx={{ width: `${value ? width + 30 : width}px` }}
                    disabled={disabled}
                    getOptionLabel={getLabel}
                    inputProps={{ 'aria-label': 'Without label' }}
                    multiple={multiple}
                    value={renderValue()}
                    onChange={(event, newValue) => {
                        onChange(newValue);
                        props.onChange && props.onChange()
                    }}
                    PaperComponent={({ children }) => (
                        <AutoCompleteOptions style={{ backgroundColor: white, borderRadius: '10px' }}>
                            {children}
                        </AutoCompleteOptions>
                    )}
                    renderTags={(value, getTagProps) => {
                        const numTags = value.length;
                        const limitTags = 1;

                        return (
                            <>
                                {value.slice(0, limitTags).map((option, index) => (
                                    <Chip
                                        {...getTagProps({ index })}
                                        key={index}
                                        label={option.name}
                                    />
                                ))}
                                <XSSpaceBetween />
                                {numTags > limitTags && ` +${numTags - limitTags} `}
                            </>
                        );
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            size='small'
                            placeholder={`${required ? "*" : ""}${placeholder}`}
                        />
                    }
                    renderOption={(optionProps, option, { index }) => {
                        return (
                            hideOption({ ...option, ...props }) || (
                                <React.Fragment key={index}>
                                    {option?.moreOptions ? (
                                        <WrapMoreOptions>
                                            <Typography variant="body2" color="textSecondary">
                                                {option.moreOptions}
                                            </Typography>
                                        </WrapMoreOptions>
                                    ) :
                                        <>
                                            {
                                                option?.createNew ?
                                                    <MenuItem
                                                        onClick={() => createNew.action()}
                                                    >
                                                        <b> {createNew.label}</b>
                                                    </MenuItem>
                                                    :
                                                    (
                                                        getLabel(option) &&
                                                        <MenuItem {...optionProps}
                                                            key={option.id || index}
                                                            value={option}>
                                                            {getLabel(option)}
                                                        </MenuItem>
                                                    )
                                            }
                                        </>
                                    }
                                </React.Fragment>
                            )
                        )
                    }}
                />
            </WrapFieldContent>
            <Error {...props} />
        </WrapField>
    );
}

const isRender = (prev, next) => {
    const render =
        !isEqual(prev.options, next.options) ||
        !isEqual(prev.input, next.input) ||
        !isEqual(prev.touched, next.touched) ||
        !isEqual(prev.error, next.error)
    return !render
}

export default memo(AutoComplete, isRender)

