import { useQuery, useQueryClient } from "react-query"
import { queryClient as qc } from 'keys'
import { getAssessmentDefFetch } from "store/middlewares/assessment.crud"
import { useSearchParams } from "react-router-dom"
import actions from "store/actions"
import { useDispatch } from "react-redux"

const useGetAssessmentDef = () => {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch()

    const assessmentDefID = searchParams.get('assessmentDef');

    return useQuery(
        {
            queryKey: qc.assessmentDef,
            queryFn: ({ signal }) => getAssessmentDefFetch({ id: assessmentDefID, signal, }),
            enabled: !!assessmentDefID,
            onSuccess: (res) => {
                dispatch(actions.setAssessmentDef(res.data))
            }
        })
}

export default { useGetAssessmentDef }