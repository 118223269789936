import React from "react"
import AssessmentDefsCrud from "./AssessmentDefs.crud"
import { useSelector } from "react-redux"

const AssessmentDefsData = () => {
    const { updateAssessmentDefSelected, searchAssessmentDefs } = AssessmentDefsCrud()
    const { selectedAssessmentDefs } = useSelector(state => state.assessment)

    const selectAssessmentDefs = {
        checked: (value) => selectedAssessmentDefs.includes(value),
        onChange: updateAssessmentDefSelected
    }

    const assessmentKeysForSearch = ["type_name", "readability_score_name", "affiliation_name", "author", "name"]

    const search = {
        component: "input",
        placeholder: "חיפוש...",
        className: "assessment-def-search"
    }

    const headerActions = [

    ]

    return {
        selectAssessmentDefs,
        headerActions,
        assessmentKeysForSearch,
        search,
        headerActions
    }
}

export default AssessmentDefsData