import React from 'react'
import Auth from 'components/Auth'
import Validate from './Validate'
import { Form } from 'components/Form'
import { LoginCrud, LoginData, constants } from './hooks'
import { RegisterLink, ForgotUserNameOrPassword } from '../Style'
import { app } from 'components/Router/routes'
import { Link } from 'styles/GlobalStyle'

const Login = () => {
    const { formName, title, fields, submitBtns } = LoginData()
    const { login } = LoginCrud()
    const { forgotPassword, forgotUsername, register } = constants

    return (
        <Auth>
            <Form
                form={formName}
                title={title}
                submit={login}
                validations={Validate}
                fields={fields}
                submitBtns={submitBtns}
            />
            <ForgotUserNameOrPassword>
                <Link to={app.recoverUsername}>{forgotUsername}</Link>
                <Link to={app.reset}>{forgotPassword}</Link>
            </ForgotUserNameOrPassword>
            <RegisterLink to={app.register}>
                {register}
            </RegisterLink>
        </Auth>
    )
}

export default Login