import styled from "styled-components";
import { Header as HeaderDataGrid } from 'components/DataGrid/Style'
import { Button } from "@mui/material";
import { lightPrimary, lightBlue2 } from "styles/theme";
import { filter } from "styles/constant";

const Header = styled(HeaderDataGrid)`
width:100%;
display: grid;
grid-template-columns:auto auto auto 1fr ${filter} auto;
heihgt:40px;
justify-content: stretch;
${props => props.headercss && props.headercss};
`

const IndicationLabel = styled.div`
color:${props => props.color};
`

const WrapBasicButton = styled(Button)`
box-shadow: none !important;
border:1px solid ${lightPrimary} !important;
`

const WrapFullButton = styled(Button)`
box-shadow: none !important;
border:1px solid ${lightBlue2} !important;
`

const WrapIndication = styled.div`
.MuiSvgIcon{
    color:pink !important
}
`

export { Header, WrapIndication, IndicationLabel, WrapBasicButton, WrapFullButton }