import React from 'react'
import TestTakerHeader from 'components/School/TestTakers/TestTakerHeader'

const WrapAppRoute = (props) => {
    const { isGeneralPage, children } = props

    return (
        <>
            {!isGeneralPage &&
                <TestTakerHeader />
            }
            {children}
        </>
    )

}

export default WrapAppRoute