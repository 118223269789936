import { RemoveRedEye, LocalLibrary, NotificationsNone } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { isEmpty, keyBy, map } from 'lodash'
import { RatingCrud } from '.';
import { RatingList, Summary, Challenges } from 'components/School/Rating'
import { gender, semesters as semesterKeys } from 'keys';

const RatingData = () => {
    const { rating } = useSelector(state => state.rating)
    const { assessmentDefs, assessment } = useSelector(state => state.assessment)

    const { goWords, goMatchLearning } = RatingCrud()

    const { semester, reading_assessment_name } = assessment || {}
    const { female, male } = gender

    const assessmentDefsKeys = keyBy(assessmentDefs, "id")
    const defualtRating = "60"

    const challenges = [
        {
            id: 0,
            label: "קצב ודיוק",
            color: "dark",
            fullWidth: true,
            align: 'space-between ',
            component: "button"
        },
        {
            id: 6,
            component: "label",
            label: "בקרוב!",
            fontSize: 16
        },
        {
            id: 1,
            label: "אבני יסוד",
            color: "light",
            disabled: true,
            disabledColor: "gray2",
            fullWidth: true,
            align: 'start',
            component: "button"
        },
        {
            id: 2,
            label: "הבנת הנקרא ופרוזודיה",
            color: "light",
            disabled: true,
            disabledColor: "gray2",
            fullWidth: true,
            align: 'start',
            component: "button"
        },
        {
            id: 2,
            label: "תפקודי שפה",
            color: "light",
            disabled: true,
            disabledColor: "gray2",
            fullWidth: true,
            align: 'start',
            component: "button"
        },
        {
            id: 2,
            label: "תפקודי ניהול",
            color: "light",
            disabled: true,
            disabledColor: "gray2",
            fullWidth: true,
            align: 'start',
            component: "button"
        }
    ]

    const ratingSections = [
        {
            id: 0,
            title: "חוזקות ואתגרים",
            width: 380,
            flex: true,
            bg: true,
            component: Challenges
        },
        {
            id: 1,
            title: "קצב ודיוק",
            flex: true,
            bg: true,
            grow: true,
            component: Summary,
        },
        {
            id: 2,
            title: "גרף התקדמות",
            bg: true,
            width: 450,
            component: () => null
        },
        {
            id: 3,
            bg: true,
            width: 340,
            component: RatingList
        }
    ]

    const detailsTestTakerSections = [
        {
            id: 0,
            bg: true,
            shrink: true,
            width: 200,
            content: keyBy(semesterKeys, "id")[semester]?.name
        },
        {
            id: 1,
            bg: true,
            grow: true,
            content: reading_assessment_name
        }
    ]

    const stringsG = {
        write: {
            [female]: "קוראת",
            [male]: "קורא"
        },
        heOrShe: {
            [female]: "היא",
            [male]: "הוא"
        },
        writen: {
            [female]: "קראה",
            [male]: "קרא"
        }
    }

    const ratingObj = {
        "30": {
            id: "30", name: "30"
        },
        "45": {
            id: "45", name: "45"
        },
        "60": {
            id: "60", name: "60"
        }
    }


    const ratingSelect = {
        options: map(ratingObj)
    }

    const actions = [
        {
            id: 0,
            label: "צפייה במבדק",
            action: goWords,
            icon: <RemoveRedEye fontSize='small' />
        },
        {
            id: 1,
            label: "התאמת למידה",
            action: goMatchLearning,
            icon: <LocalLibrary fontSize="small" />,
            disabled: isEmpty(rating),
            disabledColor: "gray2"
        }
    ]

    return {
        ratingObj,
        actions,
        ratingSelect,
        defualtRating,
        ratingSections,
        detailsTestTakerSections,
        assessmentDefsKeys,
        challenges,
        stringsG,
    }
}

export default RatingData