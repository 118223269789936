import React from 'react'
import { useSelector } from 'react-redux'
import { CustomSection } from 'styles/GlobalStyle'
import { TestTakerName, TestTakerDetails } from './Style'
import PlaceHolder from './PlaceHolder'

const TestTakerHeader = () => {
    const { testTaker } = useSelector(state => state.testTaker)
    const { test_taker_name, grade_name, t_class_name, affiliation_name } = testTaker || {}

    return (
        <CustomSection $cGap={5} $noShadow height={45} align="center" $flex>
            {test_taker_name ?
                <>
                    <TestTakerName>
                        {`${test_taker_name}`}
                    </TestTakerName>
                    {grade_name && t_class_name && affiliation_name &&
                        <TestTakerDetails>
                            {` - ${grade_name}${t_class_name} - ${affiliation_name} `}
                        </TestTakerDetails>
                    }
                </>
                :
                <PlaceHolder />
            }
        </CustomSection>
    )
}

export default TestTakerHeader