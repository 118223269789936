import { secretStorage } from "config"
import { cloneDeep, isEmpty } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "store"

const updatePulldownStorage = (key, values) => {
    const user = secretStorage.getItem("user")
    const { user_data } = user || {}

    let nUser_data = cloneDeep(user_data)
    nUser_data[key] = values
    let nUser = { ...user, user_data: nUser_data }
    console.log(nUser)
    secretStorage.setItem("user", nUser)
}

const useHandleGroup = () => {
    const dispatch = useDispatch()

    return (filters) => {
        const user = secretStorage.getItem("user")
        const { user_data } = user || {}
        const { affiliations, grades, classes, affiliation_groups } = user_data || {}

        if (!isEmpty(affiliations) && !isEmpty(filters)) {
            const nAffiliations = affiliations.filter(
                affiliation =>
                    affiliation.group === filters?.group?.id)

            dispatch(actions.setAffiliations(nAffiliations))
        }
    }
}

const useHandleAffiliation = () => {

}

const useHandleGrade = () => {
    const dispatch = useDispatch()

    const user = secretStorage.getItem("user")
    const { user_data } = user || {}
    const { affiliations, grades, classes, affiliation_groups } = user_data || {}

    return (filters) => {
        if (!isEmpty(grades) && !isEmpty(filters)) {
            const nClassess = classes.filter(
                tClass =>
                    tClass.grade === filters?.grade?.id
                    &&
                    tClass.affiliation === filters?.affiliation?.id
            )

            console.log("nClassess", nClassess, filters?.grade?.id, filters?.affiliation?.id)
            dispatch(actions.setTClasses(nClassess))
        }
    }
}

const useDefaultValue = () => {
    const dispatch = useDispatch()

    return (nUser) => {
        const currentUser = nUser || secretStorage.getItem("user")
        const { user_data } = currentUser || {}
        const { affiliations, grades, classes, affiliation_groups } = user_data || {}

        let header = {}
        if (affiliation_groups?.length === 1) {
            header.group = affiliation_groups[0]
        }
        if (affiliations?.length === 1) {
            header.affiliation = affiliations[0]
        }
        if (grades?.length === 1) {
            header.grade = grades[0]
        }
        if (classes?.length === 1) {
            header.t_class = classes[0]
        }

        if (!isEmpty(header))
            dispatch(actions.setHeaderFilters(header))
    }

}

export default { updatePulldownStorage, useDefaultValue, useHandleAffiliation, useHandleGrade, useHandleGroup }
