import { TestTakersCrud } from 'components/School/TestTakers/hooks';
import { timeAgoWithOutSeconds } from 'utils/formatter';
import { AssessmentsAndTestTakersCrud, AssessmentsAndTestTakersData } from 'components/School/AssessmentsAndTestTakers/hooks';
import { StatusCell } from 'components/School/AssessmentsAndTestTakers'
import { WarningCell } from 'components/School/TestTakers'
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Add, FileUploadOutlined } from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { loading as loadingKeys } from 'keys';
import { GridAction } from 'components/Form';

import TestTakerHooks from 'components/School/TestTakers/hooks'

const TestTakersData = () => {
    const { addNewClass, addNewGrade, goAddChild, sortDates, sortLastResults, goBatteryAllocation, geCreateRecordAssessment, createUploadAssessment, updateMasterDetails, } = TestTakersCrud()

    const { showAllAssessments, showReportField } = AssessmentsAndTestTakersData()
    const { printAssessments } = AssessmentsAndTestTakersCrud()

    const nameField = TestTakerHooks.useNameField()

    const { loading } = useSelector(state => state.general)
    const { selectedTestTakers } = useSelector(state => state.testTaker)

    const sortModel = [
        nameField.sortModel
    ];

    const fields = [
        nameField.field,
        {
            field: 'affiliation_name',
            headerName: "שם בית הספר",
            headerAlign: 'center',
            align: "center",
            width: 150,
        },
        {
            field: 'grade_name',
            headerName: "שם הכיתה",
            headerAlign: 'center',
            align: "center",
            width: 150,
        },
        {
            field: 't_class_name',
            headerName: "מספר",
            headerAlign: 'center',
            align: "center",
            width: 150,
        },
        {
            field: 'last_assessments',
            headerName: 'תוצאות אחרונות',
            width: 150,
            headerAlign: 'center',
            align: "center",
            renderCell: WarningCell,
            sort: sortLastResults,
            valueGetter: (value) => value.row.rating
        },
        {
            field: 'create_date',
            headerName: 'אבחון אחרון',
            headerAlign: 'center',
            type: 'numbr',
            width: 150,
            align: "center",
            sort: (sortModel) => {
                sortDates(sortModel, "create_date")
            },
            valueGetter: (value) => `${timeAgoWithOutSeconds(value.row?.last_assessment?.create_date) || ""}`
        },
        {
            field: 'last_assessment.def_name',
            headerName: "שם האבחון",
            headerAlign: 'center',
            align: "center",
            width: 150,
            valueGetter: (value) => value.row.last_assessment?.def_name,
        },
        {
            field: 'last_assessment.status',
            headerName: "סטטוס תוצאות",
            width: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: StatusCell,
            valueGetter: (value) => `${value.row?.last_assessment?.sub_status || ""}`,
        },
        showReportField('last_assessment'),
        {
            field: 'actions',
            type: 'actions',
            headerName: "אבחון חדש",
            width: 110,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            cellClassName: 'actions',
            feature: "create-record-assessment",
            role: "create",
            getActions: ({ row }) => {
                return [
                    <GridActionsCellItem
                        icon={<Add color='yellow' />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => {
                            geCreateRecordAssessment(row)
                        }}
                        color="secondary"
                    />
                ];
            }
        },
        {
            field: 'upload_file',
            type: 'actions',
            headerName: "העלאת אודיו",
            width: 110,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            cellClassName: 'actions',
            feature: "create-upload-assessment",
            role: "create",
            permissionHOC: "disabled",
            getActions: ({ row }) => {
                return [
                    <GridAction
                        {...row}
                        icon={FileUploadOutlined}
                        action={() => createUploadAssessment(row)}
                        color="secondary"

                    />
                ];
            }
        },
        showAllAssessments('last_assessment'),

    ]

    const headerBtns = [
        {
            id: 0,
            label: 'הדפס דו"ח',
            name: "report",
            action: printAssessments,
            component: "button",
            height: "35px",
            width: '120px',
            color: "rating1",
            disabled: isEmpty(selectedTestTakers),
            disabledColor: 'rating1',
            loading: loading[loadingKeys.printAssessment],
            disabled: true,
        },
        {
            id: 0,
            label: "הקצאת אבחונים",
            name: "battery",
            action: goBatteryAllocation,
            height: "35px",
            width: '140px',
            color: "dark",
            disabled: isEmpty(selectedTestTakers),
            disabledColor: 'dark',
            component: "button",

            feature: "bulk-create",//TODO move to json features
            role: "create",
            permissionHOC: "hidden",

            // customFeature: true,
            // feature: "create-testTkaer"
        },
        {
            id: 2,
            label: "+ הוספת ילד.ה",
            action: goAddChild,
            color: "secondary",
            height: "35px",
        }
    ]

    return {
        fields,
        headerBtns,
        sortModel
    }

}

export default TestTakersData