import React from 'react'
import { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'
import { WrapGraph, WrapIcon, WrapNumChild, GraphNumber, WrapGraphItem, WrapChild } from './Style'
import { firstWord } from 'utils/obj'
import { Progress } from 'components/Record'
import { FlexCenter } from 'styles/GlobalStyle'

const GradeGraph = () => {
    const { testTaker = {} } = useSelector(state => state.testTaker)
    const { scoreRating = {}, classStats } = useSelector(state => state.rating)

    return (
        <WrapGraph>
            {classStats ? Object.keys(classStats).map((graphNumber, index) => {
                const item = classStats[graphNumber]

                const { rating_id, count, current_test_taker } = item

                const theme = {
                    color: scoreRating[rating_id]?.color
                }

                return (
                    <ThemeProvider theme={theme} key={index}>
                        <WrapGraphItem current_test_taker={current_test_taker}>
                            <WrapNumChild>
                                {current_test_taker ?
                                    firstWord(testTaker.test_taker_name) :
                                    count > 1 && (`+${count}`)}
                            </WrapNumChild>
                            <WrapChild>
                                {count >= 1 && <WrapIcon />}
                            </WrapChild>
                            <GraphNumber>
                                {graphNumber}%
                            </GraphNumber>
                        </WrapGraphItem>
                    </ThemeProvider>
                )
            }
            ) :
                <FlexCenter>
                    <Progress />
                </FlexCenter>
            }
        </WrapGraph>

    )
}

export default GradeGraph