import React from 'react'
import { Left, MuiButton, Right, Center, NumPoints, WrapWordsHeader, ReplyIcon, WrapAssessmentName, WrapMarkWordsContent, WrapHeaderActions, WrapFooter, WrapMarkWords, WrapScreenMarkWords } from './Style'
import { Button, fieldsTyps } from 'components/Form'
import { CustomFab, CustomSection, SpaceBetween, WrapHeader } from 'styles/GlobalStyle'
import { WordsCrud } from './hooks'
import { Print } from '@mui/icons-material'
import { Icon, SvgIcon } from '@mui/material'

const GeneralHeaderActions = ({ name }) => <WrapAssessmentName>{name}</WrapAssessmentName>

const Header = (props) => {
    const { name, customHeader, renderType, contentToPrint, print, words, headerActions, hidePoints, goRatingBtn, hideBackBtn, customGoBack } = props

    const { goBack, printWords } = WordsCrud()

    const points = renderType.points(words?.length)

    return (
        <CustomSection align="center" $noShadow height={60} $flex justify="space-between">
            <WrapWordsHeader>
                <Left>
                    <WrapAssessmentName>{name}</WrapAssessmentName>
                    <SpaceBetween />
                    {
                        customHeader?.left?.map((act, index) => {
                            const Component = fieldsTyps[act.component]
                            return (
                                <Component {...act} key={index} />
                            )
                        })
                    }
                </Left>
                <Center>
                    {
                        customHeader?.center?.map((act, index) => {
                            const Component = fieldsTyps[act.component]
                            return (
                                <Component {...act} key={index} />
                            )
                        })
                    }
                </Center>
                <Right>
                    {
                        customHeader?.right?.map((act, index) => {
                            const Component = fieldsTyps[act.component]
                            return (
                                <Component {...act} key={index} />
                            )
                        })
                    }
                    {!print &&
                        <CustomFab size='small' color='primary' onClick={() => printWords(contentToPrint)}>
                            <Print />
                        </CustomFab>}
                    {goRatingBtn && <Button label='דו"ח אישי' />}
                    {/* {!hidePoints &&
                        points &&
                        <NumPoints>
                            {`${points} נקודות`}
                        </NumPoints>} */}
                    {!hideBackBtn &&
                        <CustomFab size='small' color='dark' onClick={customGoBack ? customGoBack : goBack}>
                            <ReplyIcon />
                        </CustomFab>}
                </Right>
            </WrapWordsHeader>
        </CustomSection>
    )
}

export default Header