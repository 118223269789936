import ratingColors from 'keys/rating'

class ScoreRatingMethod {

    constructor(scoreRatingMethod) {
        const { id, percent_accuracy_rating, f_rating, rating, index, words_per_minute, method, semester, method_name } = scoreRatingMethod

        this.id = id
        this.index = index
        this.method = method
        this.rating = rating
        this.color = ratingColors[index - 1]
        this.themeColor = `rating${index}`
        this.method_name = method_name
        this.semester = semester
        this.words_per_minute = words_per_minute
        this.percent_accuracy_rating = percent_accuracy_rating
        this.f_rating = f_rating
    }
}

export default ScoreRatingMethod