
const useIdField = () => {
    const isField = {
        field: 'id',
        headerName: "ID",
        width: 140,
        align: 'center',
        headerAlign: "center",
        feature: "col-ID",
        role: "read"
    }
    return isField
}

export default useIdField