import React from 'react'
import { validate } from '.'
import { BasicAlert } from 'components'
import { WrapCreateAffiliation } from './Style'
import { Form } from 'components/Form'
import { CreateGradeData, CreateGradeCrud } from './hooks'
import { useSelector } from 'react-redux'
import grade from 'components/School/CreateGrade/hooks'

const CreateTestTaker = () => {
    const { testTaker } = useSelector(state => state.testTaker)
    const { formName, fields, title, submitBtns } = CreateGradeData()
    const { handleCreateGradeAlert } = CreateGradeCrud()
    const { headerFilters } = useSelector(state => state.header)

    const createGrade = grade.useCreateGrade()

    return (
        <BasicAlert title={title} handleAlert={handleCreateGradeAlert}>
            <Form
                form={formName}
                title={null}
                validations={(values) => validate(values, testTaker)}
                fields={fields}
                csswrap={WrapCreateAffiliation}
                submitBtns={submitBtns}
                submit={createGrade}
                initialValues={headerFilters}
            />
        </BasicAlert>
    )
}

export default CreateTestTaker