import React from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { WrapSummary, WrapRate, Summary, WrapSelectRating, WrapAccuracy, WrapSummaryData } from './Style'
import { RatingData } from './hooks'
import { BoldSummary, HeaderSummary } from './Style'
import { firstWord } from 'utils/obj'
import { FlexCenter } from 'styles/GlobalStyle'
import { Progress } from 'components/Record'

const SummaryComponent = () => {
    const { stringsG } = RatingData()
    const { rating, scoreRating = {} } = useSelector(state => state.rating)
    const { testTaker } = useSelector(state => state.testTaker)
    const { test_taker_name, gender } = testTaker || {}
    const { percent_accuracy = "?", num_words_per_minute, factored_percent_accuracy, factored_percent_accuracy_rating_id, percent_accuracy_rating_id, correct_words_per_minute = "?", fluency_rating_id } = rating || {}

    const { f_rating, color: accuracyColor } = scoreRating[percent_accuracy_rating_id] || {}
    const { color: scColor } = scoreRating[factored_percent_accuracy_rating_id] || {}
    const { percent_accuracy_rating, color: rateColor } = scoreRating[fluency_rating_id] || {}

    const { write } = stringsG

    return (
        (!test_taker_name || isEmpty(rating)) ?
            <FlexCenter>
                <Progress />
            </FlexCenter>
            :
            <WrapSummary>
                <HeaderSummary>
                    {` ${firstWord(test_taker_name)} ${write[gender]} בקצב `}
                    <BoldSummary color={rateColor}>
                        {percent_accuracy_rating}
                    </BoldSummary>
                </HeaderSummary>
                <HeaderSummary>
                    {` רמת דיוק `}
                    <BoldSummary color={accuracyColor}>
                        {f_rating}
                    </BoldSummary>
                </HeaderSummary>
                <WrapSummaryData>
                    <WrapRate>
                        {` קצב -`}
                        <BoldSummary color={rateColor}>
                            {num_words_per_minute}
                        </BoldSummary>
                        {"מילים לדקה"}
                    </WrapRate>
                    <WrapAccuracy>
                        {` אחוז דיוק - `}
                        <BoldSummary color={accuracyColor}>
                            {percent_accuracy}%
                        </BoldSummary>
                        {factored_percent_accuracy &&
                            <>
                                {" "}
                                (
                                {"אחרי שקלול - "}
                                <BoldSummary color={scColor}>
                                    {factored_percent_accuracy}%
                                </BoldSummary>
                                )
                            </>
                        }
                    </WrapAccuracy>
                </WrapSummaryData>
                <Summary>
                    {` מילים נכונות לדקה - `}
                    <BoldSummary>
                        {correct_words_per_minute}
                    </BoldSummary>
                </Summary>
            </WrapSummary >
    )
}

export default SummaryComponent