import { ArrowBack, ChevronLeft, Mic, Replay, West } from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { AssessmentsAndTestTakersCrud, AssessmentsAndTestTakersData } from 'components/School/AssessmentsAndTestTakers/hooks';
import { AssessmentStatusCell } from 'components/School/AllAssessments'
import { timeAgoWithOutSeconds } from 'utils/formatter';
import { statuses } from 'keys'
import { done, error, secondary, thirdly, yellow } from 'styles/theme';

import AssessmentsHooks from 'components/School/Assessments/hooks';
import TestTakerHooks from 'components/School/TestTakers/hooks'
import { AllAssessmentsCrud } from '.';
import { assessmentUtils } from 'helpers';
import { Button } from 'components/Form';

const TestTakersData = () => {
    const { playField } = AssessmentsAndTestTakersData()
    const { goRecord } = AllAssessmentsCrud()
    const { inProgress, backToCutting, backToTranscription, backToMarking, uploaded, uploading, brokenFile, created } = statuses

    const header = AssessmentsHooks.useHeaderItems()
    const nameField = TestTakerHooks.useNameField()

    const headerItems = [
        {
            ...header[0],
            disabled: true,
            defaultValue: new Date().getFullYear()
        },
        {
            ...header[1],
            disabled: true,
            defaultValue: assessmentUtils.getCurrentSemester()
        },
        header[2],
    ]

    const fields = [
        nameField.field,
        {
            field: 'assessment_count',
            headerName: "מספר שלבים",
            width: 200,
            headerAlign: 'center',
            align: 'center',
            // renderCell: AssessmentStatusCell,
            // valueGetter: (value) => `${value.row || ""}`,
        },
        {
            field: 'status',
            headerName: "סטטוס",
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: AssessmentStatusCell,
            valueGetter: (value) => `${value.row || ""}`,
        },
        {
            field: 'file_uploaded_date',
            headerName: "עדכון ביצוע",
            headerAlign: 'center',
            width: 200,
            align: "center",
            valueGetter: ({ row }) =>
                timeAgoWithOutSeconds(
                    row.assessment_group.reduce((latestDate, assessment) => {
                        const assessmentDate = new Date(assessment.file_uploaded_date);
                        return assessmentDate > latestDate ? assessmentDate : latestDate;
                    }, null))
                || ""
        },
        {
            field: 'record',
            type: 'actions',
            headerName: "ביצוע",
            width: 200,
            headerAlign: 'center',
            align: 'center',
            cellClassName: 'actions',
            getActions: ({ row }) => {
                const isBrokenFile = row.status === statuses.brokenFile
                // const disabledRow = !isBrokenFile && row.audio_file_url

                const { assessment_group } = row
                const disabledRow = !(assessment_group.find((ass) => !ass.audio_file_url || ass.status === statuses.brokenFile))

                return [
                    <Button
                        className="action_btn"
                        direction="ltr"
                        icon={
                            // isBrokenFile ?
                            // <Replay color={'yellow'} /> :
                            <ArrowBack fontSize='small' color={'white'} />
                        }
                        label="לביצוע"
                        action={() => {
                            goRecord(row)
                        }}
                        color="secondary"
                        disabled={disabledRow}
                        disabledColor="secondary"
                    />
                ];
            }
        },
        playField
    ]

    const mappingByStatus = {
        [inProgress]: {
            label: "הסתיים",
            border: done
        },
        [backToMarking]: {
            label: "הסתיים",
            border: done
        },
        [backToCutting]: {
            label: "הסתיים",
            border: done
        },
        [backToTranscription]: {
            label: "הסתיים",
            border: done
        },
        [uploaded]: {
            label: "הסתיים",
            border: done
        },
        [uploading]: {
            label: "בהעלאה...",
            border: yellow,
        },
        [created]: {
            label: "לא בוצע",
        },
        [brokenFile]: {
            label: "אודיו לא תקין",
            border: error
        },
        ["unComplete"]: {
            label: "לא בוצע",
            border: secondary
        },
        ["part"]: {
            label: "חלקי",
            border: yellow
        },
        ["complete"]: {
            label: "הסתיים",
            border: done
        }
    }

    return {
        headerItems,
        mappingByStatus,
        fields,
        columnVisibilityModel: {}
    }
}

export default TestTakersData