import axios from 'config/axiosConfig'

import actions from '../actions'
import api, { assessment, definitions, reading_assessment, assessments, admin_assessments } from 'keys/apis'
import { MiniWordRM } from 'models/RM'
import { WordVM } from 'models/VM'

let CancelToken = axios.CancelToken;

export const getWords = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_WORDS') {
        let { id } = getState().assessment.assessment
        if (!id)
            id = action.payload

        if (id)

            return new Promise((resolve, reject) => {

                axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${id}/get_words/`)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        return null
                    });
            })
    }
    return next(action)
}

export const getNestedWordsFetch = ({ id, signal }) => {
    if (id)
        return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/get_words_nested/`)
}

export const getWordsFetch = async ({ id, signal }) => {
    if (id)
        return await axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${id}/get_words/`, {
            signal
        })
}

export const getResults = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_RESULTS') {
        const { id } = getState().assessment.assessment
        const { letterAddOn } = getState().static
        const { staticWords } = getState().words

        // const { staticData } = action.payload

        return new Promise((resolve, reject) => {

            axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}assessments/${id}/get_results/`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    return null
                });
        })
    }
    return next(action)
}

export const saveWords = ({ dispatch, getState }) => next => action => {
    if (action.type === 'SAVE_WORDS') {
        const { id } = getState().assessment.assessment
        const words = action.payload

        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/save_words/`, {
                words: words
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(null)
                });
        })
    }
    return next(action)
}

export const saveWordsFetch = async ({ assessment, words }) => {
    let nWords = []
    words.forEach((word, index) => {
        nWords.push(new MiniWordRM({ ...word, timePoint: assessment.timePoint }, index))
    });

    await axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${assessment.id}/save_words/`, {
        words: nWords
    })
    return nWords
}

export const saveWord = ({ dispatch, getState }) => next => action => {
    if (action.type === 'SAVE_WORD') {
        const { id } = getState().assessment
        const word = action.payload

        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}assessments/${id}/save_word/`, {
                ...word
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(null)
                });
        })
    }
    return next(action)
}

export const saveWordFetch = ({ word, assessmentID }) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${assessmentID}/save_word_nested/`, {
        ...word
    })
}

export const getWordDefs = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_WORD_DEFS') {
        let id = action.payload

        return new Promise((resolve, reject) => {

            axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${definitions}${id}/get_word_defs/`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    return null
                });
        })
    }
    return next(action)
}

export const getStaticWords = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_STATIC_WORDS') {
        const { id } = getState().assessment

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}assessments/${id}/get_definitions/`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    return null
                });
        })
    }
    return next(action)
}

export const clearData = ({ dispatch, getState }) => next => action => {
    if (action.type === 'CLEAR_DATA') {
        const word_id = action.payload
        const { id } = getState().assessment

        return new Promise((resolve, reject) => {
            axios.delete(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}assessments/${id}/delete_word/`, {
                data: {
                    word_id: word_id
                }
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(null)
                });
        })
    }
    return next(action)
}

export const deleteWordFetch = ({ assessmentID, wordID }) => {
    return axios.delete(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${assessmentID}/delete_word/`, {
        data: {
            word_id: wordID
        }
    })
}

export const getAudioFile = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_AUDIO_FILE') {
        const { assessment } = getState().assessment
        const { controllers } = getState().general
        const { id } = action.payload || {}

        controllers[api.get_audio_file]()

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}assessments/${id || assessment.id}/download_audio_file/`,
                {
                    responseType: 'blob',
                    // cancelToken: new CancelToken(function executor(c) {
                    //     // An executor function receives a cancel function as a parameter
                    //     dispatch(actions.updateController({ [api.get_audio_file]: c }))
                    // })
                })
                .then(response => {
                    const audioURL = URL.createObjectURL(new Blob([response.data]));

                    dispatch(actions.setAudioData(audioURL))
                    resolve(audioURL)
                })
                .catch(error => {
                    console.error(error);
                    reject(error)
                });
        })
    }
    return next(action)
}

export const saveReadingAssessment = ({ dispatch, getState }) => next => action => {
    if (action.type === 'SAVE_READING_ASSESSMENT') {
        const { assessment } = getState().assessment
        const id = assessment?.id
        const data = action.payload

        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}assessments/${id || data.id}/save/`, {
                ...data
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    reject(error)
                });
        })
    }
    return next(action)
}