import React, { useState } from 'react'
import Auth from 'components/Auth'
import Validate from './Validate'
import { Form } from 'components/Form'
import { constants, useFields, useSubmit } from './hooks'
import { RegisterLink, InstructionItem, Instructions } from 'components/Auth/Style'
import { app } from 'components/Router/routes'
import { resetPasswordFetch } from 'store/middlewares/auth.crud'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { toastConfig } from 'config'


const ResetPassword = () => {
    const { backToLogin, formName, resetPasswordSentTitle, instructions, resetPasswordSentSubTitle, title, subTitle } = constants

    const [resetPasswordSent, setResetPasswordSent] = useState(false)

    const fields = useFields()
    const submitBtns = useSubmit()

    const { mutateAsync: resetPassword } = useMutation({
        mutationFn: resetPasswordFetch,
        onSuccess: () => {
            setResetPasswordSent(true)
        },
        onError: (error) => {
            if (error?.response?.data?.error === 'User not found')
                toast.error('משתמש לא קיים במערכת', toastConfig)
            else toast.error("שגיאה", toastConfig)
        }
    })

    return (
        <Auth>
            {resetPasswordSent ?
                <Form
                    title={resetPasswordSentTitle}
                    subTitle={resetPasswordSentSubTitle}
                    fields={fields}
                >
                    <Instructions>
                        {instructions.map((instruction) =>
                            <InstructionItem>
                                {instruction}
                            </InstructionItem>
                        )}
                    </Instructions>
                </Form>
                :
                <Form
                    form={formName}
                    title={title}
                    subTitle={subTitle}
                    submit={resetPassword}
                    validations={Validate}
                    fields={fields}
                    submitBtns={submitBtns}
                />
            }
            <RegisterLink to={app.login}>
                {backToLogin}
            </RegisterLink>
        </Auth>
    )
}

export default ResetPassword