import React, { useContext, useEffect, useState } from 'react';
import { CreateAssessmentRecordCrud, CreateAssessmentRecordData } from './hooks';
import { WrapFooterActions, WrapUploadFab, WrapUpload, WrapRecordActions, WrapAssessmentRecord, WrapRecord } from './Style'
import { Fab } from 'components/Form';
import { MicContext } from './AssessmentRecordFooter';
import { Play } from 'components/School/CreateAssessmentRecord'
import { CircularProgress, Step } from '@mui/material';
import { RemoveRedEye } from '@mui/icons-material';

const Mic = (props) => {
    const { customActions = {} } = props

    const { initMic, stop } = CreateAssessmentRecordCrud()
    const { isRecordingRef, audioURL, isPlaying, blobURL } = useContext(MicContext)
    const { actionFabs, clean, playRecord, recordFab, uploadFab, } = CreateAssessmentRecordData()

    useEffect(() => {
        initMic()

        return (() => {
            if (isRecordingRef.current)
                stop()
        })
    }, [])

    const uploadFabItem = customActions?.upload || uploadFab

    return (
        <>
            <WrapFooterActions align="start">
                {actionFabs.map((fab, index) =>
                    <Fab key={index} {...fab} />
                )}
            </WrapFooterActions>
            <WrapFooterActions align="center">
                <Fab {...clean} />
                <Fab {...playRecord} />
                <Fab {...recordFab} />
            </WrapFooterActions>
            <WrapFooterActions align="end">
                <Fab
                    {...uploadFabItem}
                    {...uploadFab}
                    onClick={() => {
                        uploadFabItem.onClick(blobURL)
                    }}
                    loading={uploadFabItem.loading}
                // icon={{
                //     icon: uploadFabItem.loading ? CircularProgress : uploadFabItem.icon?.icon,
                //     color: "disabled"
                // }}
                />
            </WrapFooterActions>
            <Play url={audioURL} isPlaying={isPlaying} />
        </>
    );
}

export default Mic;