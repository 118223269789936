import React, { useContext } from 'react'
import { Close, Done } from '@mui/icons-material'
import { IconButton } from 'components/Form'
import { secretStorage } from 'config'
import { useDispatch } from 'react-redux'
import { actions } from 'store'
import { PaymentContext } from './PaymentContext'
import { checkCouponCodeFetch } from 'store/middlewares/auth.crud'
import { toastConfig } from 'config'
import { toast } from 'react-toastify'
import { app } from 'components/Router/routes'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { formname } from 'components/Auth/Register/hooks/constants'

const CouponDone = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const registerForm = useSelector(state => state.form[formname]?.values)

    const { couponCode } = useContext(PaymentContext)

    const { mutateAsync: checkCouponCode, isLoading: checkCouponCodeLoading } = useMutation({
        mutationFn: checkCouponCodeFetch,
        onError: (error) => {
            if (error?.response?.data?.error === 'Coupon has already been used')
                toast.error('קוד קופון אינו בתוקף', toastConfig)
            else toast.error("קוד קופון אינו קיים", toastConfig)
        }
    })

    const useCoupon = async () => {
        const formValues = secretStorage.getItem("registerForm")
        const secretUser = secretStorage.getItem("user")

        if (secretUser) {
            dispatch(actions.setUser({ user_id: secretUser.user_id }))
        }

        const secretData = formValues?.test_taker_id ? { ...formValues, new_balance: 1 } : registerForm
        secretStorage.setItem("registerForm", secretData)

        if (couponCode) {
            const res = await checkCouponCode({ coupon_code: couponCode })
            if (res)
                navigate(app.thankYou)
        }
    }


    return (
        <IconButton
            height={30}
            width={30}
            color='dark'
            icon={<Done color="white" />}
            action={useCoupon}
            disabled={checkCouponCodeLoading}
        />
    )
}

export default CouponDone