import React from 'react';
import { GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExportContainer, GridToolbarFilterButton, GridToolbarQuickFilter, } from '@mui/x-data-grid';
import { GridToolbarExport } from '@mui/x-data-grid';
import { GridToolbarColumnsButton } from '@mui/x-data-grid';
import { WrapGridToolbarContainer, WrapGridToolbarQuickFilter } from './Style'
import { Can } from 'components/Permissions';

const CustomToolbar = () => {
    return (
        <WrapGridToolbarContainer>
            <div>
                <Can I={"read"} an={"toolbar-actions"}>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarExport />
                </Can>
            </div>
            <WrapGridToolbarQuickFilter />
        </WrapGridToolbarContainer>
    );
}

export default CustomToolbar