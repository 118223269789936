import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { RouterData } from "components/Router/hooks";
import { WrapAppRoute } from 'components/Router'
import { Can } from 'components/Permissions'
import { AbilityContext } from "components/Permissions/Can";

const CreateRouter = () => {
    const { appRoutes } = RouterData()
    const ability = useContext(AbilityContext)

    return (
        <Routes>
            {appRoutes.map((route, index) => {

                return (
                    <React.Fragment key={index}>
                        {
                            ability.can(route.role, route.feature) &&
                            <Route
                                key={route.id}
                                exact
                                path={`${route.path}`}
                                element={
                                    route.navigate ?
                                        <Navigate to={route.navigate} /> :
                                        <WrapAppRoute {...route}>
                                            <route.element />
                                        </WrapAppRoute>
                                }
                            />
                        }
                    </React.Fragment>

                )
            },
            )}
        </Routes>
    );
}

export default CreateRouter
