
const qc = {
    adminAssessments: "adminAssessments",
    words: "words",
    assessment: "assessment",
    testTaker: "testTaker",
    openGrades: "openGrades",
    rating: "rating",
    getAudioFile: "getAudioFile",
    initAIServer: "initAIServer",
    whisper: "whisper",
    assessmentDef: "assessmentDef",
    tClassess: "tClassess",
    nestedWords: "nestedWords",
    vowelCodes: "vowelCodes",
    letterAddOns: "letterAddOns",
    letterDescriptions: "letterDescriptions",
    assessments: "assessments",
    getWithLastAssessments: "getWithLastAssessments",
    report: "report",
    testTakers: "testTakers",
    grades: "grades",
    dashboard: "dashboard"
}

export default qc