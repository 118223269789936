import React, { useEffect, useState } from 'react'
import Auth from 'components/Auth'
import Validate from './Validate'
import { Form } from 'components/Form'
import { constants, useFields, useSubmit } from './hooks'
import { app } from 'components/Router/routes'
import { resetPasswordConfirmFetch, resetPasswordFetch, saveNewPasswordFetch } from 'store/middlewares/auth.crud'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { toastConfig } from 'config'
import { useNavigate, useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { FlexCenter } from 'styles/GlobalStyle'

const ResetPassword = () => {
    const { formName, title } = constants

    const [isConfirmResetList, setIsConfirmRestLink] = useState(false)
    const submitBtns = useSubmit()
    const params = useParams()
    const fields = useFields()
    const navigate = useNavigate()

    const uid = params.uid
    const token = params.token
    const resetData = { uid: uid, token: token }

    const { mutateAsync: saveNewPassword } = useMutation(saveNewPasswordFetch, {
        onSuccess: () => {
            toast('הסיסמא התעדכנה בהצלחה', toastConfig)
            navigate(app.login)
        }
    })
    const { mutateAsync: resetPasswordConfirm } = useMutation({
        mutationFn: resetPasswordConfirmFetch,
        onSuccess: () => {
            setIsConfirmRestLink(true)
        },
        onError: (error) => {
            if (error?.response?.data?.error == "Invalid user ID") {
                toast.error("משתמש לא קיים במערכת", toastConfig)
            }
            else {
                toast.error("לינק לא תקין/לא בתוקף", toastConfig)
            }
        }
    })

    useEffect(() => {
        resetPasswordConfirm(resetData)
    }, [])

    const submit = async (values) => {
        await saveNewPassword({ ...values, uid: uid })
    }

    return (
        <Auth>
            {isConfirmResetList ?
                <Form
                    form={formName}
                    title={title}
                    submit={submit}
                    validations={Validate}
                    fields={fields}
                    submitBtns={submitBtns}
                />
                :
                <FlexCenter>
                    <CircularProgress color='secondary' size={70} />
                </FlexCenter>
            }
        </Auth>
    )
}

export default ResetPassword