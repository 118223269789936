import React, { useEffect, useRef } from 'react';
import { AssessmentsData, AssessmentsCrud } from './hooks';
import { useSelector } from 'react-redux';
import { Assessments } from 'components/School/AssessmentsAndTestTakers';
import { dataGridModel } from 'keys';
import AssessmentsHooks from './hooks';
import { assessmentRQ } from 'store/hooks';

const AssessmentsC = () => {
    const { init, cleanData, setRowSelectionModel } = AssessmentsCrud()
    const { fields, headerBtns } = AssessmentsData()
    const { headerFilters } = useSelector(state => state.header)
    const { testTaker } = useSelector(state => state.testTaker)
    const { assessmentFilters } = useSelector(state => state.assessment)

    const headerItems = AssessmentsHooks.useHeaderItems()
    const { refetch: refetchGetAssessmentReport, remove: removeGetAsessmentReport, data } = assessmentRQ.useGetAssessmentReport()

    useEffect(() => {
        removeGetAsessmentReport()
        refetchGetAssessmentReport()
    }, [
        //onChange header filters
        headerFilters,
        //for all assessment for specific testTaker
        testTaker,
        //onChange assessment filters
        assessmentFilters])

    useEffect(() => {
        return (() => {
            cleanData()
        })
    }, [])

    return (
        <Assessments
            data={data?.data}
            fields={fields}
            headerItems={[...headerItems, ...headerBtns]}
            checkboxSelection
            onRowSelectionModelChange={setRowSelectionModel}
            visibilityModelKey={dataGridModel.assessments}
        />
    );
}

export default AssessmentsC
