import moment from "moment"
import { isEmpty, map } from "lodash"
import { convertArrayToIdsString } from "utils/formatter"
import semesters from "keys/semesters"

class AssessmentUtils {
    getAssessmentFilters = (headerFilters, assessmentFilters, statusFilters, currentTestTaker) => {
        const { group, test_taker, affiliation, grade, t_class } = headerFilters || {}
        const { year, semester, assessment_def } = assessmentFilters
        const { status, sub_status } = statusFilters

        let params = {}
        if (group) params.group = group.id
        if (t_class) params.t_class = t_class.id
        else {
            if (affiliation) params.affiliation = affiliation.id
            if (grade) params.grade = grade.id
        }

        if (semester) params.semester_and_year = `${semester?.id || semester}_${year?.id || year}`
        if (!isEmpty(assessment_def)) params.assessment_defs = convertArrayToIdsString(assessment_def)
        if (test_taker) params.test_taker = test_taker.id
        else if (currentTestTaker) params.test_taker = currentTestTaker.test_taker_id
        if (status) params.status = map(status).join(',')
        if (sub_status) params.sub_status = sub_status

        return params

    }


    // Function to determine current semester
    getCurrentSemester() {
        const date = new Date()
        const formattedDate = moment(date).format('MM-DD');

        for (const [semester, value] of Object.entries(semesters)) {
            for (const range of value.range) {
                const startDate = moment(range.start, 'MM-DD');
                const endDate = moment(range.end, 'MM-DD');

                if (moment(formattedDate, 'MM-DD').isBetween(startDate, endDate, null, '[]')) {
                    return value.id;
                }
            }
        }

        return '1';
    }

}

export default new AssessmentUtils()