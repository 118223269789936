import React, { useContext } from 'react'
import { Content, WrapTranscription } from './Style'
import { Page, Record } from 'components'
import { Header, Phonemes } from '.'
import { TranscriptionContext } from './TranscriptionContext'
import { wordsRQ, transcriptionRQ } from 'store/hooks'
import { CustomSection, WrapAll } from 'styles/GlobalStyle'
import { NestedWordVM, WordVM } from 'models/VM'
import { useSelector } from 'react-redux'

const Transcription = () => {
    const {
        letterAddOns,
        setLetterDescriptions, setWords, setLetterAddOns, setVowelCodes
    } = useContext(TranscriptionContext)
    const { isCloseSttoper } = useSelector(state => state.markingWords)

    const { } = wordsRQ.useGetNestedWords({
        onSuccess: (words) => {
            setWords(words)
        }
    })
    const { } = transcriptionRQ.useGetLetterAddOns({
        onSuccess: ({ data }) => {
            setLetterAddOns(data)
        }
    })
    const { } = transcriptionRQ.useGetVowelCodes({
        onSuccess: ({ data }) => {
            setVowelCodes(data)
        }
    })
    const { } = transcriptionRQ.useGetLetterDescriptions({
        onSuccess: ({ data }) => {
            setLetterDescriptions(data)
        }
    })

    const disabledContent = isCloseSttoper

    return (
        <Page>
            <WrapAll>
                <Content>
                    <CustomSection height={160} $bg align="center">
                        <Record changeSetting showTimeline />
                    </CustomSection>
                    <Header disabledContent={disabledContent} />
                    <WrapTranscription $disabled={disabledContent} >
                        <Phonemes />
                    </WrapTranscription>
                </Content>
            </WrapAll>
        </Page>
    )
}

export default Transcription