import { AssessmentsAndTestTakersCrud, AssessmentsAndTestTakersData } from 'components/School/AssessmentsAndTestTakers/hooks';
import { StatusCell, RateAndAccuracy, RateAndAccuracyValue, RateAndAccuracyLabel } from 'components/School/AssessmentsAndTestTakers';
import { percent, timeAgoWithOutSeconds } from 'utils/formatter';
import { ChangeCircle } from '@mui/icons-material';
import { useSelector } from 'react-redux';

import AssessmentHooks from 'components/School/Assessments/hooks';
import TestTakerHooks from 'components/School/TestTakers/hooks'
import qc from 'keys/queryClient';

const AssessmentsData = () => {
    const { showReportField, testTakerNameField, playField } = AssessmentsAndTestTakersData()
    const { sortByRateAndAccuracy, sortNumbers, updateColumnItems } = AssessmentsAndTestTakersCrud()
    const { columnItems } = useSelector(state => state.general)

    const idField = AssessmentHooks.useIdField()
    const nameField = TestTakerHooks.useNameField()

    const fields = [
        idField,
        nameField.field,
        {
            field: 'reading_assessment_name',
            headerName: "שם אבחון",
            width: 140,
            align: 'center',
            headerAlign: "center",
        },
        {
            field: 'status',
            headerName: "סטטוס תוצאות",
            width: 140,
            headerAlign: 'center',
            align: 'center',
            renderCell: StatusCell,
            valueGetter: (value) => `${value.row?.sub_status || ""}`,
        },
        {
            field: 'file_uploaded_date',
            headerName: "מועד ביצוע",
            headerAlign: 'center',
            type: 'numbr',
            width: 140,
            align: "center",
            valueGetter: (value) => `${timeAgoWithOutSeconds(value.row?.file_uploaded_date) || ""}`,
        },
        {
            field: 'rate',
            headerName: 'קצב',
            width: 140,
            align: 'center',
            headerAlign: "center",
            renderCell: columnItems["rate"] ? RateAndAccuracyLabel : RateAndAccuracy,
            valueGetter: (value) => { return { ...value.row, fields: { id: "fluency_rating_id", index: "fluency_rating_index" } } },
            sort: (sortModel) => sortByRateAndAccuracy(sortModel, "fluency_rating_index", qc.report),
            customMenu: [
                {
                    action: () => updateColumnItems(["rate", "accuracy"]),
                    icon: ChangeCircle,
                    label: `הצג ${columnItems["rate"] ? "סמלים" : "טקסטים"}`
                }
            ]
        },
        {
            field: 'score_rate',
            headerName: "מילים לדקה",
            width: 140,
            align: 'center',
            headerAlign: 'center',
            color: "red",
            renderCell: RateAndAccuracyValue,
            valueGetter: (value) => { return { ...value.row, fields: { field: "num_words_per_minute", index: "fluency_rating_index", precent: false } } },
            sort: (sortModel) => sortNumbers(sortModel, "num_words_per_minute")
        },
        {
            field: 'accuracy',
            headerName: "דיוק",
            type: 'number',
            width: 140,
            align: 'center',
            headerAlign: "center",
            renderCell: columnItems["accuracy"] ? RateAndAccuracyLabel : RateAndAccuracy,
            valueGetter: (value) => { return { ...value.row, fields: { id: "percent_accuracy_rating_id", index: "percent_accuracy_rating_index" } } },
            sort: (sortModel) => sortByRateAndAccuracy(sortModel, "percent_accuracy_rating_index", qc.report),
            customMenu: [
                {
                    action: () => updateColumnItems(["rate", "accuracy"]),
                    icon: ChangeCircle,
                    label: `הצג ${columnItems["accuracy"] ? "סמלים" : "טקסטים"}`
                }
            ]
        },
        {
            field: 'score_fluency',
            headerName: "אחוז דיוק",
            width: 140,
            align: 'center',
            headerAlign: 'center',
            renderCell: RateAndAccuracyValue,
            valueGetter: (value) => { return { ...value.row, fields: { field: "percent_accuracy", index: "percent_accuracy_rating_index", precent: true } } },
            sort: (sortModel) => sortNumbers(sortModel, "percent_accuracy")
        },
        {
            field: 'factored_percent_accuracy',
            headerName: "משוקלל",
            width: 140,
            align: 'center',
            headerAlign: 'center',
            renderCell: RateAndAccuracyValue,
            valueGetter: (value) => { return { ...value.row, fields: { field: "factored_percent_accuracy", index: "factored_percent_accuracy_rating_index", precent: true } } },
            sort: (sortModel) => sortNumbers(sortModel, "factored_percent_accuracy")
        },
        {
            field: 'error_percent',
            headerName: " טעויות",
            width: 140,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (value) => percent(value.row?.rating?.error_percent),
            sort: (sortModel) => sortNumbers(sortModel, "error_percent")
        },
        {
            field: 'repetition_percent',
            headerName: " חזרות",
            width: 140,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (value) => percent(value.row?.rating?.repetition_percent),
            sort: (sortModel) => sortNumbers(sortModel, "repetition_percent")
        },
        {
            field: 'self_correction_percent',
            headerName: " תקון עצמי",
            width: 140,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (value) => percent(value.row?.rating?.self_correction_percent),
            sort: (sortModel) => sortNumbers(sortModel, "self_correction_percent")
        },
        {
            field: 'minor_error_percent',
            headerName: " שגיאה קלה",
            width: 140,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (value) => percent(value.row?.rating?.minor_error_percent),
            sort: (sortModel) => sortNumbers(sortModel, "minor_error_percent")
        },
        {
            field: 'self_correction_and_minor_error_percent',
            headerName: " שגיאה קלה עם תקון עצמי",
            width: 150,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (value) => percent(value.row?.rating?.self_correction_and_minor_error_percent),
            sort: (sortModel) => sortNumbers(sortModel, "self_correction_and_minor_error_percent")
        },
        playField,
        showReportField(''),
    ]

    const headerBtns = [

    ]

    return {
        fields,
        headerBtns
    }
}

export default AssessmentsData