import { CustomSection } from "styles/GlobalStyle"
import { Record } from "components"

const AssessmentHeader = () => {

    return (
        <CustomSection $flex={false} $bg $shrink height={100}>
            <Record />
        </CustomSection>
    )
}

export default AssessmentHeader
