import React from 'react'
import { Logo } from 'assets'
import { WrapAuth, Left, Right, WrapLogo, FormContent } from './Style'
import { Img } from 'styles/GlobalStyle'

const Auth = (props) => {
    const { children } = props

    return (
        <WrapAuth>
            <Left>
                <WrapLogo>
                    <Img src={Logo} />
                </WrapLogo>
            </Left>
            <Right>
                <FormContent>
                    {children}
                </FormContent>
            </Right>
        </WrapAuth>
    )
}

export default Auth