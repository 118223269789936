import styled from "styled-components";
import WavesurferPlayer from '@wavesurfer/react'
import { WaveSurfer, WaveForm, Region, Marker } from "wavesurfer-react";
import { Scroll, Section } from "styles/GlobalCss";
import { Button, IconButton, Stack } from "@mui/material";
import { light, dark } from 'styles/theme'

const ActionBtn = styled(Button)`

`

const Action = styled(IconButton)`
background:#E9F0FD !important;
color:${dark} !important;
`

const WrapActions = styled.div`
display:flex;
flex-direction:column;
`

const WrapStack = styled(Stack)`
justify-content:center;
`

const AudioContent = styled.div`

`

const WrapRegion = styled(Region)`

`

const WrapWaveSurfer = styled(WaveSurfer)`

`

const Message = styled.div`
display: flex;
align-items: center;
`

const WrapRecord = styled.div`
flex-shrink: 0;
${Section};
flex-direction: ${props => props.$changeSetting && `column`};
height:auto;
display:flex;
column-gap: 10px;
justify-content: center;
flex-direction: ${props => !props.$basic && "column"};
row-gap:10px;
height:${props => props.$basic && '100px'};
display:${props => props.$hidden && 'none'};

// // ${Section};
// height:auto;
// display:flex;
// justify-content: center;
// flex-direction: ${props => !props.$basic && "column"};
// row-gap:10px;
// align-items: center;
// height:${props => props.$basic && '40px'};
// display:${props => props.$hidden && 'none'};
// column-gap: 10px;

#play{
    width:40px !important;
    height:40px !important;
}
${Scroll};

.record{
    width:100% !important;
    direction: ltr;
    ${Scroll};
    overflow-x:hidden !important;
    overflow-y:hidden !important;

}



.scroll{
    overflow-x:hidden !important;
    overflow-y:hidden !important;

    ${Scroll};
}


.linearProgress{
    width:100% !important;
}


.action-btns{
    display:flex;
    column-gap: 10px;
}

`

const BasicRecord = styled.div`
    width:calc(100% - 80px) !important;
    direction: ltr;
    display: ${props => props.$hidden && 'none'};
    height: 100%;
    ${Scroll};
`

const WrapWavesurferPlayer = styled(WaveSurfer)`
${Scroll};
overflow:hidden;
height:100%

    #waveform {
            transform: scaleX(-1);
        }
        #waveform-controls {
            transform: scaleX(-1);
        }
        .marker-label {
            transform: scaleX(-1);
        }

`

const WrapWavesurferPlayerRef = styled.div`
${Scroll};
overflow:hidden;
height:100%

`

const Duration = styled.div`
display: flex;
column-gap: 5px;
display: flex;
justify-content: space-between;
height:30px;
`

const DurationButton = styled(Button)`
background:${props => props.selected && `${light} !important`};
color:${props => props.selected && `${dark} !important`};

${props => props.$rightIcon && `
direction: ltr !important;
`}
`

const ChangeAudioSetting = styled.div`
    display: flex;
    column-gap: 5px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`


const ZoomIn = styled.div`
.zoom-in{
    display: flex;
    column-gap: 10px;
    font-size:14px
}

.slider{
    width: 170px !important;
}
`

export { BasicRecord, WrapActions, Action, WrapWavesurferPlayerRef, ZoomIn, WrapStack, ChangeAudioSetting, Duration, DurationButton, WrapWaveSurfer, WrapRegion, AudioContent, Message, WrapRecord, WrapWavesurferPlayer, ActionBtn }