import styled from "styled-components";
import { dark, ligthGray } from "styles/theme";
import { Boy } from '@mui/icons-material';
import { Scroll } from "styles/GlobalCss";

const graphH = '33px'

const WrapReports = styled.div`
display: flex;
row-gap: 10px;
flex-direction: column;
height:100%;
flex-grow: 1;
overflow: auto;
`

const WrapTitle = styled.div`
font-size:18px;
`

const WrapChallengeItem = styled.div`
width:100%
`

const WrapChallenges = styled.div`
display: flex;
width: 100%;
padding-left:3px;
row-gap: 10px;
flex-direction: column;
overflow:auto;
${Scroll};

._in_soon{
padding-right:5px;
}
`

const WrapNumChild = styled.div`
font-size:11px;
text-align: center;
height:15px;
width:${graphH};
color:${props => props.theme.color};
text-shadow: ${props => `0.5px 0.5px 0.5px ${props.theme.color}`};
`

const WrapIcon = styled(Boy)`
width:35px !important;
height:35px !important;
color:${props => props.theme.color} !important;
`

const WrapGraphItem = styled.div`
flex-grow: 1;
display: flex;
flex-direction: column;
align-items: center;
width:${graphH};
${props => props.current_test_taker && `
border-radius:4px;
border:1px solid ${ligthGray};
`} 
`

const WrapChild = styled.div`
height:37px;
width:${graphH};
`

const Number = styled.div`

`

const WrapGradeGraph = styled.div`

`

const HeaderSummary = styled.div`

`

const BoldSummary = styled.span`
color:${props => props.color};
text-shadow: ${props => `1px 1px 1px ${props.color}`};
`

const WrapDetailesTestTaker = styled.div`
display: flex;
height:40px;
width: 100%;
column-gap: 10px;

`

const WrapSelectRating = styled.span`
`

const WrapRating = styled.div`
display: flex;
row-gap: 8px;
flex-direction: column;
color: white;
`

const WrapSummaryData = styled.div`

`

const Rate = styled.div`
width:100%;
box-sizing:border-box;
background:${props => props.color};
border-radius: 2px;
padding:5px;
font-weight:400;
font-size:15px;
`

const WrapContentSection = styled.div`
display: flex;
height: 100%;
flex-direction: column;
justify-content: space-between;
`

const WrapActions = styled.div`
display: flex;
row-gap: 10px;
flex-direction: column;
`

const WrapRatingSections = styled.div`
display: flex;
column-gap: 10px;
flex-grow:1;
overflow: auto;
${Scroll};
`

const WrapRate = styled.div`
font-size:20px;
display:flex;
column-gap: 5px;
align-items:center;
`

const WrapAccuracy = styled.div`
font-size:20px;

`

const WrapSummary = styled.div`
padding:15px;
font-size:22px;
line-height: 40px;
display: flex;
flex-direction: column;
row-gap: 10px;
width: 100%;
box-sizing: border-box;
height:100;
overflow:auto;
${Scroll};
`

const AssessmentDetails = styled.div`

`

const Summary = styled.div`

`

const GraphNumber = styled.span`
height: 35px;
color:${props => props.theme.color};
width: 100%;
background:${dark};
display: flex;
justify-content: center;
align-items:center;
font-size:12px;
`


const WrapGraph = styled.div`
overflow-x:auto;
overflow-y:hidden;
display:flex;
// display: grid;
// grid-template-columns: repeat(auto-fill, 23px);
direction: ltr;
justify-content: space-between;
height:95px;
align-items: center;
width:100%;

${Scroll};
`

export { WrapChallengeItem, WrapTitle, WrapChallenges, WrapNumChild, WrapIcon, WrapChild, WrapGraphItem, WrapGraph, GraphNumber, WrapGradeGraph, HeaderSummary, BoldSummary, WrapDetailesTestTaker, WrapSelectRating, WrapContentSection, WrapActions, WrapRating, Rate, Summary, WrapSummaryData, WrapRatingSections, Number, WrapRate, WrapAccuracy, WrapReports, WrapSummary, AssessmentDetails }