import { FormCrud } from "components/Form/hooks"
import { useSelector } from "react-redux"
import { isSubmitting } from "redux-form"

const LoginData = () => {

    const formName = 'login'
    const title = "כניסה למערכת"

    const { actionSubmit } = FormCrud()

    const isSubmittingForm = useSelector((state) => isSubmitting(formName)(state))


    const fields = [{
        name: 'username',
        component: "input",
        placeholder: "שם משתמש"
    },
    {
        name: 'password',
        component: "input",
        type: 'password',
        placeholder: "סיסמה"
    }
    ]

    const submitBtns = [
        {
            id: 0,
            label: "כניסה",
            type: "submit",
            color: "secondary",
            fullWidth: true,
            action: () => actionSubmit(formName),
            loading: isSubmittingForm
        }
    ]

    return {
        formName,
        title,
        fields,
        submitBtns
    }
}

export default LoginData;