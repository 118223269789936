import React, { useEffect } from 'react'
import { Payments } from 'components'
import { register, step3 } from './hooks'
import { formname } from './hooks/constants'
import { Button } from 'components/Form'
import { WrapPrices, WrapButton } from './Style'
import { useNavigate } from 'react-router-dom'
import { app } from 'components/Router/routes'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

const Register3 = () => {
    const navigate = useNavigate()

    const formValues = useSelector(state => state.form[formname]?.values)

    const addChildBtn = step3.useAddNewChild(formname)

    const addNewChild = () => {
        navigate(app.register2, {
            state: {
                step: 2
            }
        })
        addChildBtn.action()
    }

    useEffect(() => {
        isEmpty(formValues) && navigate(app.register1)
    }, [])

    return (
        <WrapPrices>
            <Payments
                registerForm={formValues}
                moreCards={
                    isEmpty(formValues?.children) ||
                    <WrapButton>
                        <Button
                            {...addChildBtn}
                            action={addNewChild}
                            width={"180px"}
                            height={"46px"}
                        />
                    </WrapButton>
                } />
        </WrapPrices>
    )
}

export default Register3