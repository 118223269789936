

import en from 'config/strings'

const validate = (values) => {
    const errors = {}

    if (values) {
        if (!values.subject) {
            errors.subject = en.require()
        }
        if (!values.message) {
            errors.message = en.require()
        }
    }

    return errors;
}

export default validate

