import { useDispatch, useSelector } from "react-redux"
import { actions } from "store"
import { AlertCrud } from "components/Alerts/hooks"
import { alerts } from "keys"
import { AffliationRM } from "models/RM"
import { toastConfig } from "config"
import { toast } from "react-toastify"
import { pulldowns, testTakerRQ } from "store/hooks"
import { mapKeys, pick } from "lodash"

const Affliation = () => {
    const dispatch = useDispatch()
    const { setAlerts } = AlertCrud()
    const { groups } = useSelector(state => state.affiliation)
    const { testTaker } = useSelector(state => state.testTaker)

    const { refetch: refetchAffiliations } = testTakerRQ.useGetAffiliations()

    const createAffiliation = async (values) => {
        try {
            const affiliation = new AffliationRM(values)
            let nAffiliation = await dispatch(actions.createAffiliation(affiliation))
            // const affiliations = await dispatch(actions.getAffiliations({ ...testTaker }))
            // affiliations && dispatch(actions.setAffiliations(affiliations))
            handleCreateAffiliationAlert(false)
            // let currentAffiliation = affiliations.find((affiliation) => affiliation.id === nAffiliation.id)
            // dispatch(actions.updateTestTaker({ affiliation: currentAffiliation }))
            toast(" היחידה נוצרה בהצלחה , התנתק והתחבר מחדש", toastConfig)

            // const res = await refetchAffiliations()
            // console.log("res", res)
            // if (res.data) {
            //     const nGrades = res.data.data.map(obj => pick(obj, ["name","id","group"]))
            //     pulldowns.updatePulldownStorage("affiliations", nGrades)
            // }
        }
        catch {
            console.log("error createAffiliation")
        }
    }

    const handleCreateAffiliationAlert = (value) => {
        setAlerts(alerts.createAffliation, value)
    }

    return {
        createAffiliation,
        handleCreateAffiliationAlert,
        groups,
    }
}

export default Affliation