import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { actions } from 'store'
import { app, assessmentDef } from "components/Router/routes"
import { filter, keyBy, map } from "lodash"
import { strContains } from "lodash-contrib"
import { StoreCrud } from "store/hooks"

const AssessmentDefsCrud = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { getAssessmentDefs } = StoreCrud()

    const { assessmentDefs, selectedAssessmentDefs, selectedAssessmentDef, assessment } = useSelector(state => state.assessment)

    const updateAssessmentDefSelected = (assessment, selected, multiple) => {
        const { id } = assessment

        if (multiple) {
            const nSelectedAssessmentDefs = keyBy(selectedAssessmentDefs, "id")
            if (nSelectedAssessmentDefs[id])
                delete nSelectedAssessmentDefs[id]
            else nSelectedAssessmentDefs[id] = { ...assessment, selected: selected }
            dispatch(actions.setSelectedAssessmentDefs(map(nSelectedAssessmentDefs)))
        }
        else {
            if (selectedAssessmentDef?.id === id)
                dispatch(actions.setSelectedAssessmentDef(null))
            else dispatch(actions.setSelectedAssessmentDef(assessment))
        }
    }

    const goAssessment = (id, header, footer, student) => {
        dispatch(actions.setAssessment({ ...assessment, reading_assessment_def_id: id }))
        navigate(`${app.assessmentDef}?assessmentDef=${id}${assessment?.id ? `&assessment=${assessment?.id}` : ""}`,
            {
                state: {
                    footer: footer,
                    header: header,
                    student: student,
                }
            }
        )
    }

    const init = async () => {
        if (!assessmentDefs) {
            getAssessmentDefs()
        }
    }

    return {
        goAssessment,
        init,
        updateAssessmentDefSelected,
    }
}

export default AssessmentDefsCrud