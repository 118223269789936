
import axios from "config/axiosConfig";
import api, { app_auth } from "keys/apis";

export const loginFetch = async (values) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${app_auth}${api.login}/`, {
        ...values
    })
}

export const checkCouponCodeFetch = async (values) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${app_auth}${api.use_coupon_code}/`, {
        ...values
    })
} 

export const generateCouponCodeFetch = async () => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${app_auth}${api.generate_coupon_code}/`)
} 

export const logoutFetch = async (values) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${app_auth}${api.logout}/`)
}

export const resetPasswordFetch = async (values) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${app_auth}${api.resetPassword}/`, {
        ...values
    })
}


export const resetPasswordConfirmFetch = async (values) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${app_auth}${api.confirm_reset_password}/`, {
        ...values
    })
}

export const sendVerificationEmailFetch = async (values) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${app_auth}${api.send_verification_email}/`, {
        ...values
    })
}

export const verifyEmailFetch = async (values) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${app_auth}${api.verify_email}/`, {
        ...values
    })
}

export const recoverUsernameFetch = async (values) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${app_auth}${api.recover_username}/`, {
        ...values
    })
}

export const isVerifyEmailFetch = async (values) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${app_auth}${api.is_verify_email}/`, {
        ...values
    })
}

export const saveNewPasswordFetch = async (values) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${app_auth}${api.save_new_password}/`, {
        ...values
    })
}

export const registerFetch = async (values) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${app_auth}${api.register}/`, {
        ...values
    })
}

export const validateTzFetch = (tz) => {
    if (!tz) return true
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${app_auth}${api.validate_tz}/`, {
        tz: tz
    })
}

export const validateEmailFetch = (email) => {
    if (!email) return true
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${app_auth}${api.validate_email}/`, {
        email: email
    })
}

export const validateUsernameFetch = (username) => {
    if (!username) return true
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${app_auth}${api.validate_username}/`, {
        username: username
    })
}