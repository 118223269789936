import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Button } from 'components/Form';

const AlertDialog = (props) => {
    const { handleAlert, btns, content, title } = props

    const handleClose = () => {
        handleAlert(false)
    }

    return (
        <React.Fragment>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {btns.map((btn, index) =>
                        <Button key={index} {...btn} />
                    )}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default AlertDialog