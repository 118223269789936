import React, { useContext, useEffect } from 'react'
import { Alert } from 'components'
import { Form } from 'components/Form'
import { alerts as alertKeys } from 'keys'
import { AlertCrud } from 'components/Alerts/hooks'
import { TestTakerForm, WrapStepFields, WrapTestTakerForm } from './Style'
import { useSelector } from 'react-redux'
import { initialize, reset } from 'redux-form'
import { useDispatch } from 'react-redux'
import { testTakerHooks, validate } from 'components/School/CreateTestTaker'

const NewChildPopup = () => {
    const { alerts } = useSelector(state => state.general)
    const dispatch = useDispatch()

    const { setAlerts } = AlertCrud()
    // const { currentChild, setCurrentChild } = useContext(RegisterContext)

    const fields = testTakerHooks.useFields()
    const submitBtns = testTakerHooks.useSubmitBtns()
    const setAlert = testTakerHooks.useAlert()

    useEffect(() => {
        return (() => {
            dispatch(reset("test_taker"))
            dispatch(initialize('test_taker', {}));
        })
    }, [])

    return (
        <Alert
            systemUI
            title={"מה הפרטים של הילד שאותו נאבחן?"}
            footerActions={submitBtns}
            handleAlert={() => setAlert(false)}
        >
            <WrapTestTakerForm>
                <Form
                    form={"test_taker"}
                    validations={validate}
                    fields={fields}
                    csswrap={TestTakerForm}
                    submit={() => { }}
                    initialValues={alerts[alertKeys.createTestTaker]?.props}
                />
            </WrapTestTakerForm>
        </Alert>
    )
}

export default NewChildPopup