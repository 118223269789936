import React, { useCallback } from 'react'
import { MuiButton } from 'components/Words/Style'
import { SvgIcon } from '@mui/material'
import { alerts } from 'keys'
import { AlertCrud } from 'components/Alerts/hooks'
import { ReactComponent as InstructionsIcon } from 'assets/instructions.svg';

const Instructions = () => {
    const { setAlerts } = AlertCrud()

    const openInstructions = useCallback(() => {
        setAlerts(alerts.recordInstructions, true)
    }, [])

    return (
        <MuiButton sx={{ width: 400 }} onClick={openInstructions}>
            <SvgIcon component={InstructionsIcon} inheritViewBox />
            <b>{"איך מתבצע האבחון?"}</b>
            {" לחצו כאן להסבר על השלבים"}
        </MuiButton>
    )
}

export default Instructions