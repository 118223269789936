import styled from "styled-components";
import { Scroll, Shadow } from "styles/GlobalCss";
import { gray, white } from "styles/theme";


const PageContent = styled.div`
display: flex;
// justify-content: center;
align-items:center;
height:100%;
width:100%;
`

const WrapCover = styled.div`
background:red;
width:100%;
`

const Circle = styled.div`
height:90%;
width:70%;
background:${white};
border-radius:7000px;
`

const Title = styled.div`
display: flex;
justify-content: start;
align-items:center;
height:100%;
font-size:45px;
padding-right:10%;
`

const WrapTitle = styled.div`
font-weight: bold; 
// transform: scaleX(-1); 
display: flex;
column-gap:10px; 
justify-content: center;
flex-wrap:wrap;
width:90%;
`

const PageText = styled.div`
display: flex;
font-size: ${props => props.fontsize ? `${props.fontsize}px` : '30px'};
line-height:50px;
padding: 20px;
font-weight: ${props => props.$bold && 'bold'};
`

const PageHeader = styled.div`
height: 30px;
display: flex;
justify-content:${props => props.left ? 'start' : 'end'};
`

const WrapPageContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    .page-header {
      height: 30px;
      font-size: 100%;
      text-transform: uppercase;
      text-align: right;
      height: 20px;
      // transform: scaleX(-1);
    }

    .number {
      border: 1px solid #bfbfc5;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      border-radius: 30px;
      padding: 5px;
      color: #bfbfc5;
      font-weight: bold;
    }

    .page-image {
      height: 100%;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .page-text {
      height: 100%;
      flex-grow: 1;
      text-align: justify;
      margin-top: 10px;
      padding-top: 10px;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
    }

    .page-footer {
      height: 30px;
      border-top: solid 1px hsl(35, 55, 90);
      font-size: 80%;
      color: hsl(35, 20, 50);
    }

`

const WrapPage = styled.div`
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  height: 100%;
  
  background-color: hsl(35, 55, 98);
  color: hsl(35, 35, 35);

  overflow-y: auto;
  overflow-x: hidden;
  ${Scroll};
  
`

const WrapPageCover = styled.div`
&.page-cover {
    background-color:#FFD6D4;
    background-image:${props => props.$firstPage && `url('./MaskBook.png')`};
    background-size: cover;
    padding:0px;
    overflow:hidden;
    color: hsl(35, 35, 35);
    // border:1px solid ${gray};
    // display:flex;
    // justify-content: center;
    // align-items: center; 
    ${Shadow};


    h2 {
      text-align: center;
      padding-top: 50%;
      font-size: 210%;
    }

    &.page-cover-top {
      box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4);
    }

    &.page-cover-bottom {
      box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), 10px 0 8px 0px rgba(0, 0, 0, 0.4);
    }
  }
`

export { WrapCover, Title, Circle, PageContent, PageText, PageHeader, WrapPage, WrapPageContent, WrapTitle, WrapPageCover }