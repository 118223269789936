import { useSelector } from "react-redux"
import { HeaderCrud } from "."
import { AffiliationCrud } from "components/School/CreateAffiliation/hooks"
import { CreateTestTakerCrud } from "components/School/CreateTestTaker/hooks"
import { CreateGradeCrud } from "components/School/CreateGrade/hooks"
import { CreateClassCrud } from "components/School/CreateClass/hooks"

const HeaderData = () => {
    const { affiliations, groups } = useSelector(state => state.affiliation)
    const { grades, testTakers, tClasses } = useSelector(state => state.testTaker)

    const { handleCreateGradeAlert } = CreateGradeCrud()
    const { handleCreateClassAlert } = CreateClassCrud()
    const { handleCreateAffiliationAlert, } = AffiliationCrud()
    const { handleCreateTestTakerAlert } = CreateTestTakerCrud()
    const { handleChangeAffiliationGroup, handleChangeTestTaker, clearPullDowns, handleChangeAffiliation, handleChangeTClasses, handleChangeGrade } = HeaderCrud()

    const pulldowns = [
        {
            id: 2,
            placeholder: "שם הקבוצה",
            options: groups,
            name: "group",
            handleChange: handleChangeAffiliationGroup,
            component: "autoComplete",
            width: 200
        },
        {
            id: 1,
            placeholder: "שם היחידה",
            options: [
                { createNew: true }
            ].concat(affiliations),
            name: "affiliation",
            createNew: {
                label: "הוספת יחידה",
                action: () => handleCreateAffiliationAlert(true)
            },
            handleChange: handleChangeAffiliation,
            component: "autoComplete",
            width: 170
        },
        {
            id: 3,
            placeholder: "שכבה",
            name: "grade",
            handleChange: handleChangeGrade,
            component: "autoComplete",
            width: 130,
            options: [
                { createNew: true }
            ].concat(grades),
            createNew: {
                label: "הוספת שכבה",
                action: () => handleCreateGradeAlert(true)
            },
        },
        {
            id: 4,
            placeholder: "כיתה",
            options: [
                { createNew: true }
            ].concat(tClasses),
            name: "t_class",
            handleChange: handleChangeTClasses,
            component: "autoComplete",
            width: 130,
            createNew: {
                label: "הוספת כיתה",
                action: () => handleCreateClassAlert(true)
            },
        },
        {
            id: 5,
            placeholder: "שם התלמיד",
            options: testTakers,
            name: "test_taker",
            handleChange: handleChangeTestTaker,
            component: "autoComplete",
            width: 200
        },
        // {
        //     id: 6,
        //     label: "ניקוי",
        //     name: "clear",
        //     action: clearPullDowns,
        //     component: "button",
        //     height: '42px',
        //     width: '80px',
        //     color: "secondary"
        // },
    ]


    return {
        pulldowns
    }
}

export default HeaderData